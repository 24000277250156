import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { bodyDecryption } from "../common/RedirectPathMange";
import AWS from "aws-sdk"
import Swal from "sweetalert2";


export default function IndustrySetting({ onSubmit, appSettings, isView }) {
  const [previewImage, setPreviewImage] = useState("");
  const [uploadImage, setUploadImage] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  // const [setterData, setSetterData] = useState([]);
  const {
    handleSubmit,
    register,
    setValue,
    clearErrors,
    watch,
    control,
    formState: { errors },
  } = useForm();

    // Handler for image preview
    const handleImageChange = (event) => {
      if (event.target.files && event.target.files[0]) {
        // console.log('Selected file:', event.target.files[0]);
        setPreviewImage(URL.createObjectURL(event.target.files[0]));
        setUploadImage(event.target.files[0]);
        setValue("industry_image", event.target.files[0]);
        setIsEdit(true)
      }
    };

  useEffect(() => {
    if (appSettings) {
      const isView = window.location.pathname.split("/")[2];
      let industryId = window.location.pathname.split("/")[3];
      let decIndustryId = bodyDecryption(decodeURIComponent(industryId));

      // console.log(isView, "view=====");
      // console.log(industryId, "industry=====");
      // console.log(decIndustryId, "dec=====");
      
      const { industry_name, fields_name, field_type, setter_manage, industry_image } =
        appSettings;
      if (isView === "view-industrail" || isView === "edit-industrail") {
        appSettings.filter((industry) => {
          // console.log(industry.id + "indus");
          if (industry.id == decIndustryId) {
            // setSetterData(industry);
            setIsEdit(true)
            setValue("industry_name", industry.industry_name);
            setValue("fields_name", industry.fields_name);
            setValue("field_type", industry.field_type);
            setValue("setter_manage", industry.setter_manage);
            setValue("industry_image", industry.industry_image);
            
            if (industry.industry_image) {
              setPreviewImage( process.env.REACT_APP_AWS_S3_URL + process.env.REACT_APP_INDUSTRY_IMAGE + '/' + `${industry.industry_image}`);
            }
          }
        });
      } else {
        setValue("industry_name", industry_name);
        setValue("fields_name", fields_name);
        setValue("field_type", field_type);
        setValue("setter_manage", setter_manage);

      }
    }
  }, [appSettings]);


  return (
    <div className="content-page mt-5">
      <div className="content">
        <div className="container-fluid">
          <div className="cal">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="portlet">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="horizontal_filter_card">
                      <div className="row mb-4">
                        <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                          Industry Name
                        </label>
                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input
                            // defaultValue={setterData.industry_name}
                            {...register("industry_name", {
                              required: "Industry Name is required.",
                              pattern: {
                                // value: /^(?! )[A-Za-z\s]+(?! )$/,
                                // message: "White-Space not allowed",
                                value: /^(?![\s-])[A-Za-z][\w\s-]+(?<=\S)$/,
                                message:
                                  "Only alphabetic characters and white spaces are allowed for Industry Name.",
                              },
                              maxLength: {
                                value: 250,
                                message:
                                  "Industry Name should not exceed 250 characters.",
                              },
                            })}
                            aria-invalid={
                              errors.industry_name ? "true" : "false"
                            }
                            className="form-control form-control-lg"
                            type="text"
                            placeholder="Enter Industry Name..."
                            disabled={isView}
                          />
                          {errors.industry_name && (
                            <p role="alert" className="text-danger error-msg">
                              {errors.industry_name?.message}
                            </p>
                          )}
                        </div>

                        <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                          Default Image
                        </label>
                        {!isEdit ?
                        <div className="col-12">

                          <input
                            {...register("industry_image", {
                              required: "Industry image is required.",
                            })}
                            aria-invalid={
                              errors.industry_image ? "true" : "false"
                            }
                            type="file"
                            className="form-control form-control-lg"
                            accept="image/*"
                            onChange={handleImageChange}
                            disabled={isView}
                          />
                          {errors.industry_image && (
                            <p role="alert" className="text-danger error-msg">
                              {errors.industry_image?.message}
                            </p>
                          )}
                        </div> 
                        :  <div className="col-12">
                            <input
                              type="file"
                              className="form-control form-control-lg"
                              accept="image/*"
                              onChange={handleImageChange}
                              disabled={isView}
                            />
                          </div>
                        }

                        {/* Image Preview */}
                        {previewImage && (
                          <div className="col-12 mt-3">
                            <img
                              src={previewImage}
                              alt="Preview"
                              className="img-thumbnail"
                              style={{ width: "200px", height: "200px" }}
                            />
                          </div>
                        )}
                      </div>


                      {/* <div className="row mb-4">
                        <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                          Field Name
                        </label>
                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input
                            {...register("fields_name", {
                              required: "Field Name is required.",
                              pattern: {
                                value: /^(?![\s-])[A-Za-z][\w\s-]+(?<=\S)$/,
                                message:
                                  "Only alphabetic characters and white spaces are allowed for Field Name.",
                              },
                              maxLength: {
                                value: 250,
                                message:
                                  "Field Name should not exceed 250 characters.",
                              },
                            })}
                            aria-invalid={errors.fields_name ? "true" : "false"}
                            className="form-control form-control-lg"
                            type="text"
                            placeholder="Enter Field Name..."
                            disabled={isView}
                          />
                          {errors.fields_name && (
                            <p role="alert" className="text-danger error-msg">
                              {errors.fields_name?.message}
                            </p>
                          )}
                        </div>
                      </div> */}

                      {/* <div className="row mb-4">
                        <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                          Field Type
                        </label>
                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input
                            {...register("field_type", {
                              required: "Field Type is required.",
                              pattern: {
                                value: /^(?![\s-])[A-Za-z][\w\s-]+(?<=\S)$/,
                                message:
                                  "Only alphabetic characters and white spaces are allowed for Field Type.",
                              },
                              maxLength: {
                                value: 250,
                                message:
                                  "Field type should not exceed 250 characters.",
                              },
                            })}
                            aria-invalid={errors.field_type ? "true" : "false"}
                            className="form-control form-control-lg"
                            type="text"
                            placeholder="Enter Field Type..."
                            disabled={isView}
                          />
                          {errors.field_type && (
                            <p role="alert" className="text-danger error-msg">
                              {errors.field_type?.message}
                            </p>
                          )}
                        </div>
                      </div> */}

                      {/* <div className="row mb-4">
                        <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                          Setter Manage
                        </label>
                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <div className="form-check form-check-inline">
                            <input
                         
                              {...register("setter_manage", {
                                required: "Setter Manage is required.",
                              })}
                              className="form-check-input"
                              type="radio"
                              name="setter_manage"
                              id="yes"
                              value="yes"
                              disabled={isView}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio1"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                          
                              {...register("setter_manage", {
                                required: "Setter Manage is required.",
                              })}
                              className="form-check-input"
                              type="radio"
                              name="setter_manage"
                              id="no"
                              value="no"
                              disabled={isView}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio2"
                            >
                              No
                            </label>
                          </div>
                          {errors.setter_manage && (
                            <p role="alert" className="text-danger error-msg">
                              {errors?.setter_manage?.message}
                            </p>
                          )}
                        </div>
                      </div> */}

                      {!isView && (
                        <div className="row justify-content-end">
                          <div className="col-12 col-md-12">
                            <div className="text-start">
                              <button
                                type="submit"
                                className="btn btn-primary btn-lg common_hover"
                              >
                                Save
                              </button>
                              <Link
                                to="/industrailList"
                                className="btn btn-primary btn-lg common_hover mx-2"
                              >
                                <span> Cancel </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}

                      {isView && (
                        <div className="row justify-content-end">
                          <div className="col-12 col-md-12">
                            <div className="text-start">
                              <Link
                                to="/industrailList"
                                className="btn btn-primary btn-lg common_hover"
                              >
                                <i className="mdi mdi-chevron-left"></i>{" "}
                                <span> Back </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
