import AWS from "aws-sdk";

// Ensure that environment variables are properly set
const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const region = process.env.REACT_APP_REGION;

// Update AWS SDK configuration if credentials are provided
if (accessKeyId && secretAccessKey && region) {
  AWS.config.update({
    accessKeyId,
    secretAccessKey,
    region,
  });
} else {
  console.error(
    "AWS credentials are missing or incomplete. Please check your environment variables."
  );
}

export default AWS;
