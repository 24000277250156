import React, { useEffect, useState, useRef } from 'react'
import { deleteUser, getSettersList, activeInUser } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import { bodyEncryption } from '../common/RedirectPathMange';

import FeatherIcon from "feather-icons-react";
// import Tooltip from '@material-ui/core/Tooltip';
import Tooltip from '@mui/material/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
import IconButton from '@mui/material/IconButton';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { db } from "../../config/firebase";
import { collection,doc,query,where,getDocs,writeBatch,serverTimestamp} from "firebase/firestore";
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ExcelJS from 'exceljs';
import moment from 'moment';

function SettersList() {
    const navigate = useNavigate();
    const [settersList, setSettersList] = useState([]);
    // console.log('settersList: ', settersList);
    const [totalRecordCount, setTotalRecordCount] = useState(0)
    const [pageNo, setPageNo] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [loader, setLoader] = useState(true);
    const [message, setMessage] = useState("");
    const [showModal, setShowModal] = useState(false);
    const itemsPerPage = 10;

    const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
    const [userId, setUserId] = useState(null);
    const fileInputRef = useRef(null);

    useEffect(() => {

        if(searchQuery === ''){
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading();
                }
            });
        }
        getSettersList({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery }).then((response) => {
            response = JSON.parse(response)
            
            if (response.code === 200) {
                // console.log(response)
                Swal.close();
                setLoader(false);
                setSettersList(response.data.users)
                setTotalRecordCount(Math.ceil(response.data.total_record_count / process.env.REACT_APP_RECORD_COUNT))
            } else {
                Swal.close();
                setLoader(false);
                setSettersList([]);
            }
        })

        // Swal.close();
    }, [searchQuery, pageNo]);


    const handlePageClick = (event) => {
        setPageNo(event.selected + 1)
    };

    // const viewFeedbackDetails = (item) => {
    //     var encId = encodeURIComponent(bodyEncryption((item.id).toString()))
    //     navigate(`/feedback/${encId}`);
    // }

    const viewUserDetails = (item) => {
        var encId = encodeURIComponent(bodyEncryption((item.id).toString()))
        navigate(`/setterView/${encId}`);
    }

    const showMessage = (msg) => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: msg,
            toast: true,
            showConfirmButton: false,
            timer: 1500
        })
    }

    function deleteUserIdWise(userId) {

        Swal.fire({
            title: 'Are you sure you want to delete?',
            text: "You won't be able to revert this!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#02C9D6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                // console.log("userId " + userId)
                deleteUser({ "user_id": userId }).then((response) => {
                    response = JSON.parse(response)
                    console.log(response,"bbcccbcb")
                    if (response.code === 200) {
                        // $('#mangeUser').DataTable().row("#userId" + userId).remove().draw(false);
                        showMessage(response.message);
                        // Update user list after deletion
                        // setSettersList(prevUsersList => prevUsersList.filter(user => user.id !== userId));
                        getSettersList({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery }).then((response) => {
                            response = JSON.parse(response)
                            if (response.code === 200) {
                                // console.log(response)
                                setSettersList(response.data.users)
                                setTotalRecordCount(Math.ceil(response.data.total_record_count / process.env.REACT_APP_RECORD_COUNT))
                            } else {
                                setSettersList([]);
                            }
                        })
                    }
                });
            }
        })
    }

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    }

    function changeStatus(userId, item, position) {

        var status = (item.is_active === 1) ? 0 : 1

        activeInUser({ "id": userId }).then((response) => {
            response = JSON.parse(response)
            if (response.code === 200) {
                showMessage(response.message)
                settersList[position].is_active = status
                var data = renderToStaticMarkup(renderStatusUiText(settersList[position])).replace("MuiBox-root css-4u9rcm", "MuiBox-root css-k0gowo").replace("MuiBox-root css-1oewkz4", "MuiBox-root css-lv896x")
                // $('#mangeUser').DataTable().row("#userId" + userId).cell("#userStatus" + userId).data(data).draw(false);
                getSettersList({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery }).then((response) => {
                    response = JSON.parse(response)
                    if (response.code === 200) {
                        // console.log(response)
                        setSettersList(response.data.users)
                        setTotalRecordCount(Math.ceil(response.data.total_record_count / process.env.REACT_APP_RECORD_COUNT))
                    } else {
                        setSettersList([]);
                    } 
                })
            }
        });
    }

    function renderStatusUiText(item) {
        return (
            item.is_active === 1 ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>
        )
    }

    const handleChatHistory = (user_chat_data) => {
        const chatUserData = {
            receiver_username: user_chat_data.username,
            receiver_id: user_chat_data.id,
            receiver_firstname: user_chat_data.firstname,
            receiver_image: user_chat_data.profile_picture,
            receiver_lastname: user_chat_data.lastname,
            isActive: true,
        }
        navigate('/chat',{ state : chatUserData })
    }

    
    const sendBulkMessage = async (message_type,image_file) => {
        try {
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading();
                }
            });
            handleCloseModal()
            const batch = writeBatch(db);
                
            for (const user of settersList) {
                let recentChatId = `@admin@${user.id}@`;
                const sendMessageObj = {
                    timestamp: serverTimestamp(),
                    participants: [user.id, "A1"],
                    media: message_type === "image" ? `${process.env.REACT_APP_CHAT_IMAGES_AWS_S3_URL}${recentChatId}/${image_file}` : "",
                    message: message_type === "normal" ? message : "",
                    message_type: message_type,
                    sender_device_token: "",
                    sender_username: "Admin",
                    sender_image: "",
                    sender_device_type: "",
                    sender_lastname: "Admin",
                    sender_firstname: "Admin",
                    sender_id: "A1",
                    receiver_username: user.username,
                    receiver_id: user.id,
                    recent_chat_id: recentChatId,
                    receiver_firstname: user.firstname,
                    receiver_image: user.profile_picture,
                    receiver_lastname: user.lastname,
                    chat_time: "",
                    chat_read: false
                };
        
                const q = query(
                    collection(db, process.env.REACT_APP_FIREBASE_TABLE_CHAT),
                    where("recent_chat_id", "==", recentChatId),
                    where("chat_read", "==", false),
                    where("receiver_id", "==", user.id)
                );
    
                const querySnapshot = await getDocs(q);
                const unreadCount = querySnapshot.size + 1;
    
                sendMessageObj.chat_count = unreadCount.toString();

                // Add the message to the main chat collection
                const userChatDocRef = doc(collection(db, process.env.REACT_APP_FIREBASE_TABLE_CHAT));
                batch.set(userChatDocRef, sendMessageObj);
                setMessage("");

                // Update the last message in a separate document within the recent chat collection
                const lastMessageDocRef = doc(db, process.env.REACT_APP_FIREBASE_TABLE_RECENT_CHAT, recentChatId);
                batch.set(lastMessageDocRef, sendMessageObj);
            }
        
            await batch.commit();
            console.log('Batch messages sent successfully!');
            Swal.close();
        } catch (error) {
            handleCloseModal()
            Swal.close();
            console.error('Error sending batch messages: ', error);
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: error.message,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        }
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleKeyDown = async (e) => {
        if (e.key === 'Enter' && message.trim() !== '') {
            e.preventDefault();
           await sendBulkMessage("normal","");
        }
    };

    const handleBulkUploadOpenModal = () => {
        setShowBulkUploadModal(true);
    };

    const handleBulkUploadCloseModal = () => {
        setShowBulkUploadModal(false);
    };

    const validationSchema = Yup.object().shape({
        // bulk_lead: Yup.mixed()
        //     .test(
        //         'fileRequired',
        //         'Please select an Excel file',
        //         (value) => {
        //             if (value && value.length > 0) {
        //                 return true;
        //             }
        //             return false;
        //         }
        //     )
        //     .test('fileType', 'Only Excel files are allowed', (value) => {
        //         if (!value || !value[0]) return true;
        //         const mimeType = value[0].type;
        //         return mimeType === 'application/vnd.ms-excel' || mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        //     }),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, reset } = useForm(formOptions);
    const { errors } = formState;

    const excelUpload = async(file) => {

        const workbook = new ExcelJS.Workbook();
        const reader = new FileReader();
    
    
        return new Promise((resolve, reject) => {
            reader.onload = async (e) => {
                const arrayBuffer = e.target.result;
                const data = new Uint8Array(arrayBuffer);
    
                try {
                    await workbook.xlsx.load(data);
    
                    // Assuming the first sheet is the one you want to read
                    const sheet = workbook.getWorksheet(1);
                    const dataArray = [];
    
                    // Iterate through rows and cells
                    sheet.eachRow((row, rowNumber) => {
                        if (rowNumber !== 1 && rowNumber !== 2 && rowNumber !== 3 && rowNumber !== 4) {
                            dataArray.push(row.values)
                        }
                    });
    
                    resolve(dataArray);
                } catch (error) {
                    reject(new Error('Error reading Excel file:', error.message));
                }
            };
    
            reader.readAsArrayBuffer(file);
        });
    }

    const onSubmit1 = async (data) => {
        const file = fileInputRef.current.files[0];
        if (file) {
            if(file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type == "application/vnd.ms-excel" ){
                Swal.fire({
                    title: 'Please wait...',
                    didOpen: () => {
                        Swal.showLoading()
                    }
                })
                const uploadData = await excelUpload(file)
                const leadData = uploadData
                // .filter(item => item[2] && item[3] && item[4] && item[6] && item[8] && item[5] && ['EQ', 'BE', 'BZ'].includes(item[5]?.toUpperCase()))
                .map((item, index) => {
                    return (
                        
                        {
                            id: item[1],
                            user_id: userId,    
                            Industry: item[3],
                            full_name: item[4] +" "+ item[5],
                            email: item[6].text,
                            phone: item[7] ? item[7] : null,
                            secondary_phone: item[8] ? item[8] : null, 
                            address: item[9],
                            city: item[10],
                            state: item[11],
                            zip_code: item[12],
                            custom_field: item[13] ? item[13] : null,
                            notes: item[18],
                            appt_date: item[19] ? item[19] : null,
                            appt_time: item[20] ? item[20] : null,
                            consent_to_be_connected: item[21] == "Yes" ? 1 : 0,
                            listing_title: item[27],
                            listing_description: item[29],
                            bid_start_date: moment(item[31]).utc().format('YYYY-MM-DD'),
                            bid_start_time: moment(item[32]).utc().format('HH:mm:ss'),
                            bid_end_date: moment(item[33]).utc().format('YYYY-MM-DD'),
                            bid_end_time: moment(item[34]).utc().format('HH:mm:ss'),
                            starting_bid: item[36],
                            buy_now_price: item[37],
                            auto_accept_offer: item[38],
                            is_approved: item[39],
                            vitual: item[40] == "Yes" ? 1 : 0,
                            badges: item[41]
                        }
                        )
                    })
                console.log('leadData: ', leadData);
                Swal.close()
                // await addEditCategoryFunction("add",data)
                reset()
                handleBulkUploadCloseModal()
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: 'Only Excel files are allowed',
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        } else {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'No file selected',
                toast: true,
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    return (
        <>
            <main>
                <div className='content-page mt-3'>
                    <div className='content'>
                        <div className='container-fluid'>
                            <div className='cal'>
                                <div className="col-sm-12">
                                    <div className="page-title-box d-flex justify-content-between">
                                        <h4 className="page-title">Setter Users</h4>
                                        <button className="btn btn-outline-primary" onClick={handleOpenModal}>Message</button>
                                    </div>
                                </div>
                                <div className="search-bar">
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchQuery}
                                        onChange={handleSearch}
                                    />
                                </div>
                                {loader === false ?
                                <>
                                    <div className="table-responsive">
                                        <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Username</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone Number</th>
                                                    <th>Status</th>
                                                    <th>Status Action</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            {settersList.length > 0 ? (
                                            <tbody>
                                                {
                                                    settersList && settersList.map((item, index) => {
                                                    const displayIndex = ((pageNo * itemsPerPage) - 10) + index + 1;
                                                        return (
                                                            <tr id={item.id} key={index}>
                                                                <td >
                                                                    {displayIndex}
                                                                    {/* {index+1} */}
                                                                </td>
                                                                <td >
                                                                    {item.username}
                                                                </td>
                                                                <td >
                                                                    {item.full_name}
                                                                </td>
                                                                <td >
                                                                    {item.email}
                                                                </td>
                                                                <td >
                                                                    {item.country_code + " " + item.phone_number}
                                                                </td>
                                                                {/* <td >
                                                                    {item.is_active === '0' ? <b><span style={{ "color": "Red" }}>Inactive</span></b> : <b><span style={{ "color": "Green" }}>Active</span></b>}
                                                                </td> */}
                                                                <td id={"userStatus" + item.id}>
                                                                    {renderStatusUiText(item)}
                                                                </td>
                                                                <td id={"userStatus" + item.id}>
                                                                    <Tooltip title="Change Status">
                                                                        <IconButton onClick={() => { changeStatus(item.id, item, index) }}>
                                                                            <FeatherIcon icon="repeat" width="18" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </td>
                                                                <td >
                                                                    
                                                                    {/* <button type="submit" className="Send"><i className="fa-solid fa-paper-plane"></i></button> */}

                                                                    {/* <Tooltip title="File">
                                                                        <IconButton onClick={()=>{setUserId(item.id);setShowBulkUploadModal(true);}}>
                                                                            <FeatherIcon icon="file-plus" width="18" />
                                                                        </IconButton>
                                                                    </Tooltip> */}
                                                                
                                                                    <Tooltip title="Chat">
                                                                        <IconButton onClick={() => { handleChatHistory(item) }}>
                                                                            <FeatherIcon icon="send" width="18" />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    <Tooltip title="View">
                                                                        <IconButton onClick={() => { viewUserDetails(item) }}>
                                                                            <FeatherIcon icon="eye" width="18" />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    {/* <Tooltip title="Feedback">
                                                                        <IconButton onClick={() => { viewFeedbackDetails(item) }}>
                                                                            <FeatherIcon icon="eye" width="18" />
                                                                        </IconButton>
                                                                    </Tooltip> */}

                                                                    <Tooltip title="Delete">
                                                                        <IconButton onClick={() => { deleteUserIdWise(item.id) }}>
                                                                            <FeatherIcon icon="trash-2" width="18" color="red" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            ) : (
                                                settersList.length === 0 && <h3 className='mt-3'>No User data available.</h3>                                )}
                                        </table>
                                    </div>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=" > "
                                        onPageChange={handlePageClick}
                                        // pageRangeDisplayed={pageCount}
                                        pageCount={totalRecordCount}
                                        previousLabel=" < "
                                        renderOnZeroPageCount={null}
                                        containerClassName="pagination"
                                        pageLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        disabledClassName="disabled"
                                        activeClassName="active"
                                        activeLinkClassName="active-link"
                                    />
                                </>
                                :''}
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {/* Bulk Message */}
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header>
                    <Modal.Title>Message</Modal.Title>
                    <Tooltip title="Close">
                        <IconButton onClick={handleCloseModal}>
                            <FeatherIcon icon="x-square" width="35" />
                        </IconButton>
                    </Tooltip>
                </Modal.Header>
                <Modal.Body>
                <div className="chatbody">
                    <div className="chat-main">
                        <div id="MessageForm">
                            <div className="chat-input-group">
                                <input type="text" placeholder="Enter Message..." id="MessageInput" value={message} onChange={(e)=>setMessage(e.target.value)} onKeyDown={handleKeyDown} style={{borderRadius:"10px"}} />
                                {/* <span className="input-group-text p-0" id="basic-addon2">
                                    <label for="formFile" className="form-label mb-0" style={{cursor:"pointer"}}> 
                                    <i className="fa-solid fa-paperclip font_18" style={{cursor:"pointer"}}></i></label>
                                    <input className="form-control d-none" type="file" id="formFile" accept="image/*" />
                                </span> */}
                            </div>
                            <button type="submit" className="Send" onClick={()=>sendBulkMessage("normal","")} disabled={message.trim() === ''}><i className="fa-solid fa-paper-plane"></i></button>
                        </div>
                    </div>
                </div>
                </Modal.Body>
            </Modal>

            {/* Bulk Upload Lead */}
            <Modal show={showBulkUploadModal} onHide={handleBulkUploadCloseModal} centered>
                <Modal.Header>
                    <Modal.Title>Bulk Upload</Modal.Title>
                    <Tooltip title="Close">
                        <IconButton onClick={handleBulkUploadCloseModal}>
                            <FeatherIcon icon="x-square" width="35" />
                        </IconButton>
                    </Tooltip>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit1)}>
                    <div className="mb-3">
                        <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                        >
                            File
                        </label>
                        <input
                            ref={fileInputRef}
                            type="file"
                            className="form-control"
                            id="exampleFormControlInput1"
                            accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            // {...register('bulk_lead')}
                        />
                        {/* <div className="invalid-feedback">{errors.bulk_lead?.message}</div> */}
                    </div>

                    <div className="d-flex justify-content-end">
                        <button
                            type="submit"
                            id="buttonclose"
                            className="btn btn-primary"
                        >
                            Submit
                        </button>
                    </div>    
                        
                </form>
                </Modal.Body>
            </Modal>
        </>

    );
}

export default SettersList;