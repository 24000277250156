import React, { useEffect, useRef, useState } from 'react'
import { getUserLeadList, getUsersList, deleteLead } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { bodyDecryption, bodyEncryption } from '../common/RedirectPathMange';
import moment from 'moment';
import ReactPaginate from 'react-paginate';

function CloserView() {
    const navigate = useNavigate();
    let { id } = useParams();
    var decId = decodeURIComponent(bodyDecryption(id))

    const [userDetails, setUserDetails] = useState(null)
    const [pageNo, setPageNo] = useState(1);

    const [totalRecordCount, setTotalRecordCount] = useState(0)
    const [searchQuery, setSearchQuery] = useState('');

    const [leadDetails, setLeadDetails] = useState(null)
    const [loader, setLoader] = useState(true);
    const itemsPerPage = 10;

    useEffect(() => {
        // Swal.fire({
        //     title: 'Please wait...',
        //     didOpen: () => {
        //         Swal.showLoading();
        //     }
        // });
        if (id != undefined) {
            getUsersList({ "user_id": decId,"page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT) }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    // console.log("hello",res.data)
                    // console.log("---hello",res.data.users[0].email)
                    setLoader(false);
                    setUserDetails(res.data.users[0])
                } else {
                    setLoader(false);
                    setUserDetails([])
                }
            });
        }
    }, [id])

    useEffect(()=>{
        getUserAllLeads()
    },[pageNo,searchQuery])
    
    
    const getUserAllLeads = () => {
        if(searchQuery === ''){
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading();
                }
            });
        }
        getUserLeadList({ "user_id": decId, 'search_text': searchQuery, "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT) }).then((res) => {
            res = JSON.parse(res)
            if (res.code == 200) {
                Swal.close();
                setLeadDetails(res.data.Leads)
                setTotalRecordCount(Math.ceil(res.data.total_record_count / process.env.REACT_APP_RECORD_COUNT)) 
            } else {
                Swal.close();
                setLeadDetails([])
            }
        });
    }

    const editLeadDetails = (item) => {
        if(item.is_setbid == 0){
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'The user has created a lead, but the set bid is not completed.',
                toast: true,
                showConfirmButton: false,
                timer: 1500
            })
        } else {
            var encId = encodeURIComponent(bodyEncryption((item.id).toString()))
            if(item.is_approve == 1){
                navigate(`/leadEdit/${encId}`);
            } else {
                navigate(`/toBeVerifiedEdit/${encId}`);
            }
        }
        // navigate(item.is_approve == 1 ? `/leadEdit/${encId}` : `/toBeVerifiedEdit/${encId}`);
    }

    const viewLeadDetails = (item) => {
        if(item.is_setbid == 0){
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'The user has created a lead, but the set bid is not completed.',
                toast: true,
                showConfirmButton: false,
                timer: 1500
            })
        } else {
            var encId = encodeURIComponent(bodyEncryption((item.id).toString()))
            if(item.is_approve == 1){
                navigate(`/leadView/${encId}`);
            } else {
                navigate(`/toBeVerifiedView/${encId}`);
            }
        }
        
        // navigate(item.is_approve == 1 ? `/leadView/${encId}` : `/toBeVerifiedView/${encId}`);
    }

    const showMessage = (msg) => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: msg,
            toast: true,
            showConfirmButton: false,
            timer: 1500
        })
    }

    function deleteLeadIdWise(leadId) {

        Swal.fire({
            title: 'Are you sure you want to delete?',
            text: "You won't be able to revert this!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#02C9D6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteLead({ "lead_id": leadId }).then((response) => {
                    response = JSON.parse(response)
                    if (response.code === 200) {
                        showMessage(response.message);
                        setTimeout(()=>{
                            getUserAllLeads()
                        },2000)
                    }
                });
            }
        })
    }

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    }

    function renderLeadText(item) {
        return (
            item.is_approve === 0 ? <b><span style={{ "color": "Blue" }}>Pending</span></b> : item.is_approve === 1 ?  <b><span style={{ "color": "Green" }}>Approved</span></b> : <b><span style={{ "color": "Red" }}>Rejected</span></b>
        )
    }

    const handlePageClick = (event) => {
        setPageNo(event.selected + 1)
    };

    const handleChatHistory = (user_chat_data) => {
        const chatUserData = {
            receiver_username: user_chat_data.username,
            receiver_id: user_chat_data.id,
            receiver_firstname: user_chat_data.firstname,
            receiver_image: user_chat_data.profile_picture,
            receiver_lastname: user_chat_data.lastname,
            isActive: true,
        }
        navigate('/chat',{ state : chatUserData })
    }
    
    if (userDetails == null || leadDetails == null) return <> </>

    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>
                            {/* Closer Details */}
                            <div className="row clearfix d-flex justify-content-center text-center" >
                                {loader === false ?
                                <div className="col-lg-9 col-md-12">
                                    <div className="header l-slategray">
                                        <h4 className="m-t-10">{userDetails.firstname} {userDetails.lastname}</h4>
                                    </div>
                                    <div className="row d-flex justify-content-center">
                                        <div className="member-img">
                                            {/* <img src='https://www.pngitem.com/pimgs/m/508-5087336_person-man-user-account-profile-employee-profile-template.png' className="rounded-circle" alt="profile-image" style={{ width: 200, height: 200 }} /> */}
                                            <img src={userDetails.profile_picture != undefined && userDetails.profile_picture != null ? userDetails.profile_picture : 'https://www.pngitem.com/pimgs/m/508-5087336_person-man-user-account-profile-employee-profile-template.png'} className="rounded-circle" alt="profile-image" style={{width: 200, height: 200}} />
                                        </div>
                                        <div style={{ marginTop: '150px' }}>
                                            <Tooltip title="Chat">
                                                <IconButton onClick={() => { handleChatHistory(userDetails) }}>
                                                    <FeatherIcon icon="send" width="18" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="body">
                                        <hr />
                                        <div className="row">
                                        <div className="col-4">
                                                <h5>Username</h5>
                                                <p style={{ fontSize: '15px' }}>{userDetails.username}</p>
                                            </div>
                                            <div className="col-4">
                                                <h5>Email</h5>
                                                <p style={{ fontSize: '15px' }}>{userDetails.email}</p>
                                            </div>
                                            <div className="col-4">
                                                <h5>Phone Number</h5>
                                                <p style={{ fontSize: '15px' }}>{userDetails.country_code} {userDetails.phone_number}</p>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-4">
                                                <h5>Preferred Industries</h5>
                                                {userDetails.preferred_industries == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>{userDetails.preferred_industries}</p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-4">
                                                <h5>Company Name</h5>
                                                {userDetails.company_name == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>{userDetails.company_name}</p>
                                                    </>
                                                )}
                                            </div>
                                            {/* <div className="col-4">
                                                <h5>Bank Name</h5>
                                                {userDetails.bank_name == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                        <>
                                                        <p style={{ fontSize: '15px' }}>{userDetails.bank_name}</p>
                                                    </>
                                                )}
                                            </div> */}
                                            <div className="col-4">
                                                <h5>Agreement</h5>
                                                {userDetails.signature == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <a href={userDetails.signature} target="_blank">
                                                            <p style={{fontSize:'15px',overflowWrap: 'break-word'}}>{userDetails.signature.split("/")[4]}</p>
                                                        </a>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <hr />
                                        {/* <div className="row">
                                            <div className="col-4">
                                                <h5>Account Number</h5>
                                                {userDetails.account_number == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>{userDetails.account_number}</p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-4">
                                                <h5>Routing Number</h5>
                                                {userDetails.routing_number == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>{userDetails.routing_number}</p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-4">
                                                <h5>Account Holder Name</h5>
                                                {userDetails.account_holder_name == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>{userDetails.account_holder_name}</p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <hr /> */}
                                        {/* <div className="row">
                                            <div className="col-4">
                                                <h5>Id Proof</h5>
                                                <img src={userDetails.id_proof} alt="id-proof-image" style={{ width: 250, height: 300 }} />
                                            </div>
                                            <div className="col-4">
                                                <h5>Driving License</h5>
                                                <img src={userDetails.driving_license_image} alt="driving-license-image" style={{ width: 250, height: 300 }} />
                                            </div>
                                            <div className="col-4">
                                                <h5>Tax Document</h5>
                                                <img src={userDetails.tax_document} alt="tax-document-image" style={{ width: 250, height: 300 }} />
                                            </div>
                                        </div>
                                        <hr /> */}
                                        {/* <div className="row">
                                            <div className="col-4">
                                                <h5>Rental Aggrement</h5>
                                                <img src={userDetails.rental_aggrement} alt="rental-aggrement-image" style={{ width: 250, height: 300 }} />
                                            </div>
                                            <div className="col-4">
                                                <h5>Utility Bill</h5>
                                                <img src={userDetails.utility_bill} alt="utility-bill-image" style={{ width: 250, height: 300 }} />
                                            </div>
                                        </div>
                                        <hr /> */}

                                        <div className="row">
                                            
                                            <div className="col-4">
                                                <h5>Legal Name</h5>
                                                {userDetails.firstname == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>{userDetails.firstname} {userDetails.lastname}</p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-4">
                                                <h5>Stripe ID</h5>
                                                {userDetails.stripe_customer_id == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <a href={process.env.REACT_APP_STRIPE_BASE_URL + process.env.REACT_APP_STRIPE_PATH_NAME + userDetails.stripe_customer_id} target="_blank" className="text-primary" style={{ fontSize: '15px', cursor: 'pointer', textDecoration: 'underline' }}>{userDetails.stripe_customer_id}</a>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-4">
                                                <h5>Ratings/Reviews</h5>
                                                {userDetails.totalReviewCount == 0 ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <a className="text-primary" style={{ fontSize: '15px', cursor: 'pointer', textDecoration: 'underline' }} onClick={()=> navigate('/user-leads-review-list',{ state : {"setter_id" : userDetails.id} })}>{userDetails.overallAvgRating + " Stars (" + userDetails.totalReviewCount + " Reviews)"}</a>
                                                    </>
                                                )}
                                            </div>
                                            
                                        </div>
                                        <hr />
                                        <div className="row">
                                            
                                            {/* <div className="col-4">
                                                <h5>Phone Verification</h5>
                                                <p style={{ fontSize: '15px' }}>{userDetails.is_verified == '0' ? 'No' : 'Yes'}</p>
                                            </div> */}
                                            <div className="col-4">
                                                <h5>Status</h5>
                                                <p style={{ fontSize: '14px' }}>{userDetails.is_active == '0' ? "🔴 Inactive" : "🟢 Active"}</p>
                                            </div>
                                            <div className="col-4">
                                                <h5>Created</h5>
                                                <p style={{ fontSize: '15px' }}>{ moment(userDetails.createdAt).local().format('YYYY-MM-DD hh:mm A') }</p>
                                            </div>
                                        </div>
                                        <br /><br /><br />
                                    </div>
                                    <Link to='/closersList' className="btn btn-primary btn-lg common_hover">
                                        <i className="mdi mdi-chevron-left"></i> <span> Back </span>
                                    </Link>
                                </div>
                                :''}
                            </div>

                            {/* Closer Lead Details */}
                            <div className="row container-fluid justify-content-end">
                                <div className="col-sm-12">
                                    <div className="page-title-box">
                                        <h4 className="page-title">Leads</h4>
                                    </div>
                                </div>
                                <div className="search-bar">
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchQuery}
                                        onChange={handleSearch}
                                    />
                                </div>
                                {loader === false ?
                                <>
                                    <div className="table-responsive">
                                        <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Industry</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone Number</th>
                                                    <th>Lead Approve</th>
                                                    {/* <th>Approve/Reject</th> */}
                                                    <th>Status</th>
                                                    <th>Badge</th>
                                                    {/* <th>Status</th>
                                                    <th>Status Action</th> */}
                                                    <th>Action</th>
                                                </tr>
                                            </thead>

                                            {leadDetails.length > 0 ? (
                                            <tbody>
                                                {
                                                    leadDetails && leadDetails.map((item, index) => {
                                                        const displayIndex = ((pageNo * itemsPerPage) - 10) + index + 1;
                                                        return (
                                                            <tr id={item.id} key={index}>
                                                                <td >
                                                                    {displayIndex}
                                                                </td>
                                                                <td>
                                                                    {item.industry == null ? (
                                                                        <p style={{ fontSize: '13px' }}>No data</p>
                                                                        ) : (
                                                                        <>
                                                                            {item.industry}    
                                                                        </>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {item.name == null ? (
                                                                        <p style={{ fontSize: '13px' }}>No data</p>
                                                                        ) : (
                                                                        <>
                                                                            {item.name}    
                                                                        </>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {item.email == null ? (
                                                                        <p style={{ fontSize: '13px' }}>No data</p>
                                                                        ) : (
                                                                        <>
                                                                            {item.email}    
                                                                        </>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {item.phone_number == null ? (
                                                                        <p style={{ fontSize: '13px' }}>No data</p>
                                                                        ) : (
                                                                        <>
                                                                            {item.phone_number}    
                                                                        </>
                                                                    )}
                                                                </td>
                                                                <td id={"userStatus" + item.id}>
                                                                    {renderLeadText(item)}
                                                                </td>
                                                                <td>
                                                                    {item.lead_main_status}
                                                                </td>
                                                                <td >
                                                                    {item.badges}
                                                                </td>
                                                                <td >
                                                                    <Tooltip title="View">
                                                                        <IconButton onClick={() => { viewLeadDetails(item) }}>
                                                                            <FeatherIcon icon="eye" width="18" />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    <Tooltip title="Edit">
                                                                        <IconButton onClick={() => { editLeadDetails(item) }}>
                                                                            <FeatherIcon icon="edit" width="18" color="blue"/>
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    <Tooltip title="Delete">
                                                                        <IconButton onClick={() => { deleteLeadIdWise(item.id) }}>
                                                                            <FeatherIcon icon="trash-2" width="18" color="red" />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            ) : (
                                                leadDetails.length === 0 && <h3 className='mt-3'>No Lead data available.</h3>                                )}
                                            
                                        </table>
                                    </div>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=" > "
                                        onPageChange={handlePageClick}
                                        // pageRangeDisplayed={pageCount}
                                        pageCount={totalRecordCount}
                                        previousLabel=" < "
                                        renderOnZeroPageCount={null}
                                        containerClassName="pagination"
                                        pageLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        disabledClassName="disabled"
                                        activeClassName="active"
                                        activeLinkClassName="active-link"
                                    />
                                </>
                                :''}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CloserView;