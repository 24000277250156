import React, { createContext, useState, useContext } from 'react';

const ChatCountContext = createContext();

export const useChatCount = () => {
    return useContext(ChatCountContext);
}

export const ChatCountProvider = ({ children }) => {
    const [totalChatCount, setTotalChatCount] = useState(0);
    const [questionCount, setQuestionCount] = useState(0);
    const [toBeVerifiedCount, setToBeVerifiedCount] = useState(0);
    const [inReviewCount, setInReviewCount] = useState(0);

    return (
        <ChatCountContext.Provider value={{ totalChatCount, setTotalChatCount, questionCount, setQuestionCount, toBeVerifiedCount, setToBeVerifiedCount, inReviewCount, setInReviewCount }}>
            {children}
        </ChatCountContext.Provider>
    );
}
