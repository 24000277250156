import React,{ useEffect, useState } from 'react';
import { getCommissionList, updateGlobalCommision } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";

import FeatherIcon from "feather-icons-react";
// import Tooltip from '@material-ui/core/Tooltip';
import Tooltip from '@mui/material/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
import IconButton from '@mui/material/IconButton';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AWS from 'aws-sdk';

function GlobalCommission() {
    const navigate = useNavigate();
    const [commissionList, setCommissionList] = useState([]);
    const [totalRecordCount, setTotalRecordCount] = useState(0)
    const [pageNo, setPageNo] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [loader, setLoader] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [categoryData,setCategoryData] = useState("");
    const [uploadImage, setUploadImage] = useState(null);

    useEffect(() => {
        if(searchQuery === ''){
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading();
                }
            });
        }

        getCommissionListFuncation()

        // Swal.close();
    }, [searchQuery, pageNo]);

    const getCommissionListFuncation = () => {
        getCommissionList().then((response) => {
            response = JSON.parse(response)
            
            if (response.code === 200) {
                // console.log(response)
                Swal.close();
                setLoader(false);
                setCommissionList(response.data)
            } else if (response.code === 404) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setCommissionList([]);
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setCommissionList([]);
            }
        }).catch((error)=>{
            console.log('error: ', error);
            if(error.response.status == 404){
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: 'No data found',
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setCommissionList([]);
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Something went wrong',
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setCommissionList([]);
            }
        })
    }

    const handlePageClick = (event) => {
        setPageNo(event.selected + 1)
    };
    
    const editCommission = (commission_data) => {
        // console.log('commission_data: ', commission_data);
        setCategoryData(commission_data)
        setValue2('commission',commission_data.commission,{shouldValidate : false})
        handleOpenEditModal()
    }

    const handleOpenEditModal = () => {
        setShowEditModal(true);
    };

    const handleCloseEditModal = () => {
        setShowEditModal(false);
    };


    const updateCommissionFunction = async (data) => {
    
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading();
            }
        });

        let commissionObj = {
            commission_id : categoryData.id,
            commission : data.commission,
        }

        updateGlobalCommision(commissionObj).then((response) => {
            response = JSON.parse(response)
            // console.log('response------------: ', response);
            // console.log('response: ', response);
            
            if (response.code === 200) {
                // console.log(response)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setTimeout(()=>{
                    getCommissionListFuncation()
                },500)
            } else if (response.code === 404) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
            } else if (response.code === 409) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
            }
        })
    }

    const validationSchema2 = Yup.object().shape({   
        commission: Yup.number()
            .transform((value, originalValue) => {
              // If original value is an empty string, return null
              return originalValue.trim() === "" ? null : value;
            })
            .nullable() // Allow null values after transformation
            .required("commission is required")
            .min(1, "commission must be at least 1")
            .max(100, "commission cannot be more than 100")
            // .test(
            //     "no-leading-zero",
            //     "commission cannot start with 0",
            //     value => value === null || !/^0\d+/.test(String(value))
            //   )
    });

    const formOptions2 = { resolver: yupResolver(validationSchema2) };
    const { register : register2, setValue : setValue2, watch : watch2, handleSubmit : handleSubmit2, formState : formState2, reset : reset2 } = useForm(formOptions2);
    const { errors : errors2 } = formState2;    

    const onSubmit2 = async (data) => {
        // console.log('data: ', data);
        await updateCommissionFunction(data)
        setCategoryData("")
        reset2()
        handleCloseEditModal()
    }

    return (
        <>
            <main>
                <div className='content-page mt-3'>
                    <div className='content'>
                        <div className='container-fluid'>
                            <div className='cal'>
                                <div className="col-sm-12">
                                    <div className="page-title-box d-flex justify-content-between">
                                        <h4 className="page-title">Commission</h4>
                                    </div>
                                </div>
                                {loader === false ?
                                <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                    <thead>
                                        <tr>
                                            <th>Commission</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>        
                                    {commissionList.length > 0 ? (
                                    <tbody>
                                        {
                                            commissionList && commissionList.map((item, index) => {
                                                return (
                                                    <tr id={item.id} key={index}>
                                                        <td>
                                                            {item.commission}
                                                        </td>
                                                        <td >
                                                            <Tooltip title="Edit">
                                                                <IconButton onClick={() => { editCommission(item) }}>
                                                                    <FeatherIcon icon="edit" width="18" color="blue" />
                                                                </IconButton>
                                                            </Tooltip>        
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                    ) : (
                                        commissionList.length == 0 && <h3 className='mt-3'>No commission data available.</h3> )}
                                    
                                </table>
                                :''}
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=" > "
                                    onPageChange={handlePageClick}
                                    // pageRangeDisplayed={pageCount}
                                    pageCount={totalRecordCount}
                                    previousLabel=" < "
                                    renderOnZeroPageCount={null}
                                    containerClassName="pagination"
                                    pageLinkClassName="page-link"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    disabledClassName="disabled"
                                    activeClassName="active"
                                    activeLinkClassName="active-link"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Modal show={showEditModal} onHide={handleCloseEditModal} centered>
                <Modal.Header>
                    <Modal.Title>Edit Commission</Modal.Title>
                    <Tooltip title="Close">
                        <IconButton onClick={handleCloseEditModal}>
                            <FeatherIcon icon="x-square" width="35" />
                        </IconButton>
                    </Tooltip>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={handleSubmit2(onSubmit2)}>
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Commission
                        </label>
                        <input
                        type="text"
                        {...register2('commission')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter commission"
                        // defaultValue={categoryData != "" ? categoryData.name : ""}
                        />
                        <div className="invalid-feedback">{errors2.commission?.message}</div>
                    </div>
                    
                    <div className="d-flex justify-content-end">
                        <button
                            type="submit"
                            id="buttonclose"
                            className="btn btn-primary"
                        >
                            Update
                        </button>
                    </div>    
                        
                </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default GlobalCommission;