import React, { useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ExcelJS from 'exceljs';
// import moment from 'moment';
import moment from 'moment-timezone';
import Swal from 'sweetalert2'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { bulkUploadLeads } from "../../api/apiHandler";

function BulkUploadLeads() {

    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    // const [industry, setIndustry] = useState(null);
    // console.log('industry: ', industry);
    // const [username, setUsername] = useState(null);
    // console.log('username: ', username);
    // const [customField, setCustomField] = useState([]);
    
    let industry;
    let username;
    let timezone = "US/Eastern";
    let customField = [];

    const validationSchema = Yup.object().shape({
        // bulk_lead: Yup.mixed()
        //     .test(
        //         'fileRequired',
        //         'Please select an Excel file',
        //         (value) => {
        //             if (value && value.length > 0) {
        //                 return true;
        //             }
        //             return false;
        //         }
        //     )
        //     .test('fileType', 'Only Excel files are allowed', (value) => {
        //         if (!value || !value[0]) return true;
        //         const mimeType = value[0].type;
        //         return mimeType === 'application/vnd.ms-excel' || mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        //     }),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, reset } = useForm(formOptions);
    const { errors } = formState;

    const excelUpload = async(file) => {

        const workbook = new ExcelJS.Workbook();
        const reader = new FileReader();
    
    
        return new Promise((resolve, reject) => {
            reader.onload = async (e) => {
                const arrayBuffer = e.target.result;
                const data = new Uint8Array(arrayBuffer);
    
                try {
                    await workbook.xlsx.load(data);
    
                    // Assuming the first sheet is the one you want to read
                    const sheet = workbook.getWorksheet(1);
                    const dataArray = [];
    
                    // Iterate through rows and cells
                    sheet.eachRow((row, rowNumber) => {
                        if (rowNumber == 3 || rowNumber == 4 || rowNumber == 5 || rowNumber == 6 ) {
                            if(row.values[1] == "Industry:"){
                                // setIndustry(row.values[2])
                                industry = row.values[2]
                            } else if(row.values[1] == "Username:"){
                                // setUsername(row.values[2])
                                username = row.values[2]
                            } else if(row.values[1] == "Time Zone:"){
                                timezone = row.values[2] == "Eastern Time" ? "US/Eastern" : row.values[2] == "Central Time" ? "US/Central" : row.values[2] == "Mountain Time" ? "US/Mountain" : row.values[2] == "Pacific Time" ? "US/Pacific" : "US/Eastern"
                            }
                        } else if (rowNumber == 11) {
                            customField.push(row.values[11],row.values[12],row.values[13],row.values[14],row.values[15])
                        } else if (rowNumber != 1 && rowNumber != 2 && rowNumber != 7 && rowNumber != 8 && rowNumber != 9 && rowNumber != 10 && rowNumber != 11) {
                            if(row.values[2]){
                                dataArray.push(row.values)
                            }
                        }
                    });
    
                    resolve(dataArray);
                } catch (error) {
                    reject(new Error('Error reading Excel file:', error.message));
                }
            };
            
            reader.readAsArrayBuffer(file);
        });
    }
    
    const onSubmit1 = async (data) => {
        const file = fileInputRef.current.files[0];
        if (file) {
            if(file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type == "application/vnd.ms-excel" ){
                Swal.fire({
                    title: 'Please wait...',
                    didOpen: () => {
                        Swal.showLoading()
                    }
                })
                const uploadData = await excelUpload(file)
                // console.log('uploadData: ', uploadData);
                if(industry && username){
                    let leadData = uploadData
                    // .filter(item => item[2] && item[3] && item[4] && item[6] && item[8] && item[5] && ['EQ', 'BE', 'BZ'].includes(item[5]?.toUpperCase()))
                    .map((item, index) => {
                        const leadCustomFieldsData = [
                            { field_name: customField[0], description: item[11] },
                            { field_name: customField[1], description: item[12] },
                            { field_name: customField[2], description: item[13] },
                            { field_name: customField[3], description: item[14] },
                            { field_name: customField[4], description: item[15] }
                        ].filter(field => field.description !== null    && field.description !== undefined && field.description !== "");
    
                        const leadNotesData = [
                            { title: "", notes: item[16] }
                        ].filter(note => note.notes !== null    && note.notes !== undefined && note.notes !== "");

                        return (
                            
                            {
                                // user_id: item[1],
                                // user_id: userId,    
                                industry: industry,
                                name: item[2] && item[3] ? item[2] +" "+ item[3] : item[2],
                                // email: item[4].text,
                                email: item[4] ? item[4] : null,
                                phone_number: item[5] ? item[5] : null,
                                secondary_phone_number: item[6] ? item[6] : null, 
                                // address: item[7] && item[8] && item[9] && item[10] ? item[7]+", "+item[8]+", "+item[9]+", "+item[10] : null,
                                address: item[41].result,
                                // city: item[10],
                                // state: item[11],
                                // zip_code: item[12],
                                // helper_column: item[11] ? item[11].result : null, 
                                latitude: item[42] ? item[42] : null,
                                longitude: item[43] ? item[43] : null,
                                leadCustomFieldsData: leadCustomFieldsData.length > 0 ? leadCustomFieldsData : [],
                                leadNotesData: leadNotesData.length > 0 ? leadNotesData : [],
                                is_virtual: item[17] == "Yes" ? 1 : 0,
                                // appointment_date: item[18] && item[19] ? moment(item[18]).utc().format('YYYY-MM-DD')+" "+moment(item[19]).utc().format('HH:mm:ss') : null ,
                                appointment_date: item[18] && item[19] ? convertToUTC(moment(item[18] ? (item[18].result ? item[18].result : item[18]) : item[18]).utc().format('YYYY-MM-DD')+" "+moment(item[19] ? (item[19].result ? item[19].result : item[19]) : item[19]).utc().format('HH:mm:ss'), timezone) : null ,
                                is_privacy_consent: item[20] == "Yes" ? 1 : 0,
                                status : item[21],
                                listing_title: item[22],
                                listing_description: item[24],
                                start_date_time: item[26] && item[27] ? convertToUTC(moment(item[26] ? (item[26].result ? item[26].result : item[26]) : item[26]).utc().format('YYYY-MM-DD')+" "+moment(item[27] ? (item[27].result ? item[27].result : item[27]) : item[27]).utc().format('HH:mm:ss'), timezone) : null,
                                end_date_time: item[28] && item[29] ? convertToUTC(moment(item[28] ? (item[28].result ? item[28].result : item[28]) : item[28]).utc().format('YYYY-MM-DD')+" "+moment(item[29] ? (item[29].result ? item[29].result : item[29]) : item[29]).utc().format('HH:mm:ss'),timezone) : null,
                                // bid_start_date: moment(item[28]).tz('Asia/Kolkata').utc().format('YYYY-MM-DD'),
                                // bid_start_time: moment(item[29]).tz('Asia/Kolkata').utc().format('HH:mm:ss'),
                                // bid_end_date: moment(item[30]).tz('Asia/Kolkata').utc().format('YYYY-MM-DD'),
                                // bid_end_time: moment(item[31]).tz('Asia/Kolkata').utc().format('HH:mm:ss'),
                                starting_bid_price: item[30],
                                buy_it_now_price: item[31],
                                auto_accept_offer_price: item[32] ? (item[32].result ? item[32].result : item[32]) : item[32], 
                                is_auto_accept_offer: item[32] ? 1 : 0,
                                is_upload_photos_recordings: item[33] == "Yes" ? 1 : 0,
                                is_approve: item[34] == "Approve" ? 1 : item[34] == "Reject" ? 2 : 0,
                                // is_approve: item[44].result ? item[44].result : 0,
                                badges: item[35]
                            }
                        )
                    })
                    leadData.username = username;
                    // console.log('leadData: ', leadData);
                    const params = {
                        leadsData : leadData,
                        username : username
                    }
                    // console.log('params: ', params);
                    bulkUploadLeads(params).then((response) => {
                        response = JSON.parse(response);
                        Swal.close()
                        if (response.code == 200) {
                            reset()
                            Swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: response.message,
                                toast: true,
                                showConfirmButton: false,
                                timer: 2000,
                            });
                            setTimeout(() => {
                                navigate("/");
                            }, 2000);
                        } else {
                            Swal.fire({
                                position: "top-end",
                                icon: "error",
                                title: response.message,
                                toast: true,
                                showConfirmButton: false,
                                timer: 2000,
                            });
                        }
                    });
                } else {
                    if(!industry){
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'Industry is required',
                            toast: true,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: 'Username is required',
                            toast: true,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                }
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: 'Only Excel files are allowed',
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        } else {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'No file selected',
                toast: true,
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    function convertToUTC(dateTime, timeZone) {
        // console.log('-----------------------------',name,'---------------------------------');
        // console.log('dateTime, timeZone: ', dateTime, timeZone);
        // Parse the given date and set it to the specified time zone
        const localDate = moment.tz(dateTime, timeZone);
        // console.log('localDate: ', localDate);
      
        // Convert the local time to UTC
        const utcDate = localDate.clone().utc().format('YYYY-MM-DD HH:mm:ss');
        // console.log('utcDate: ', utcDate);
        // console.log('-----------------------------',name,'---------------------------------');
      
        return utcDate == "Invalid date" ? null : utcDate;
      }

    function redirect() {
        sessionStorage.setItem("flag",0)    
        navigate("/")
    }
    
    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid' >
                        <div className='cal col-xs-11 col-md-11 col-sm-11'>
                            <div className='about mb-3'>
                                <h3>Bulk Upload</h3>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit1)}>
                                <div className="mb-3">
                                    <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                    >
                                        File
                                    </label>
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        className="form-control"
                                        id="exampleFormControlInput1"
                                        accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        // {...register('bulk_lead')}
                                    />
                                    {/* <div className="invalid-feedback">{errors.bulk_lead?.message}</div> */}
                                </div>
                               
                                <button type="submit" className="btn btn-primary waves-effect waves-light m-2"> Upload </button>
                                <button className="btn btn-danger" onClick={redirect} > Cancel </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BulkUploadLeads;