import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../../api/apiHandler';
import { logOutRedirectCall } from '../../pages/common/RedirectPathMange'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Header({ isSidebarOpen, toggleSidebar }) {
    const navigate = useNavigate();

    function logout() {
        navigate("/logout");
    }

    function changePassword() {
        navigate("/changePassword");
    }

    function editProfile() {
        navigate("/editProfile");
    }


    function redirect() {
        sessionStorage.setItem("flag",0)
        navigate("/")
    }

    return (
        <div className='wrapper'>
            <div className="topbar">
                <div className="topbar-left" style={{backgroundColor:"#043a69"}}>

                    <Link to="/" className="logo">
                        <span>
                            <img src={process.env.PUBLIC_URL + "/assets/images/logo-lead.png"} alt="" height="65" onClick={redirect}/>
                        </span>
                        {/* <i>
                            <img src={process.env.PUBLIC_URL+"/assets/images/logo-sm.png"} alt="" height="34" />
                        </i> */}
                    </Link>
                </div>
                <nav className="navbar-custom">
                    <button onClick={toggleSidebar} className="btn btn-link mt-4"> {/* Button to toggle sidebar */}
                        {isSidebarOpen ? <span className='fa-solid fa-bars'>close</span> : <span className='fa-solid fa-bars'>open</span> }
                    </button>
                    <ul className="navbar-right d-flex list-inline float-right mb-0">

                        <li className="dropdown notification-list">
                            <div className="dropdown notification-list nav-pro-img">
                                <button className="btn btn-link dropdown-toggle nav-link arrow-none waves-effect nav-user" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/userLogo.png"} alt="user" className="rounded-circle" />
                                </button>
                                <div className="dropdown-menu dropdown-menu-right profile-dropdown">

                                    <button className="btn btn-link dropdown-item" onClick={editProfile}><i className="mdi mdi-account-circle m-r-5"></i> Profile</button>
                                    <button className="btn btn-link dropdown-item" onClick={changePassword}><i className="mdi mdi-lock m-r-5"></i> Change Password</button>
                                    <div className="dropdown-divider"></div>
                                    <button className="btn btn-link dropdown-item text-danger" onClick={logout}><i className="mdi mdi-power text-danger"></i> Logout</button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default Header;