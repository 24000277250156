import React, { useEffect, useState } from 'react'
import { getUsersList, deleteUser, usersFeedbackList } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import CryptoJS from 'crypto-js';
import { bodyEncryption } from '../common/RedirectPathMange';

import FeatherIcon from "feather-icons-react";
// import Tooltip from '@material-ui/core/Tooltip';
import Tooltip from '@mui/material/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
import IconButton from '@mui/material/IconButton';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import ReactDOM from 'react-dom';

function UsersList() {
    const navigate = useNavigate();
    const [usersList, setUsersList] = useState([]);
    const [totalRecordCount, setTotalRecordCount] = useState(0)
    const [pageNo, setPageNo] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const itemsPerPage = 10;

    useEffect(() => {

        // if(searchQuery === ''){
        //     Swal.fire({
        //         title: 'Please wait...',
        //         didOpen: () => {
        //             Swal.showLoading();
        //         }
        //     });
        // }

        getUsersList({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery }).then((response) => {
            // Swal.close();
            response = JSON.parse(response)

            if (response.code === 200) {
                // console.log(response)
                setUsersList(response.data.users)
                setTotalRecordCount(Math.ceil(response.data.total_record_count / process.env.REACT_APP_RECORD_COUNT))
            } else {
                setUsersList([]);
            }
        })

        // Swal.close();
    }, [searchQuery, pageNo]);


    const handlePageClick = (event) => {
        setPageNo(event.selected + 1)
    };

    const viewFeedbackDetails = (item) => {
        var encId = encodeURIComponent(bodyEncryption((item.id).toString()))
        navigate(`/feedback/${encId}`);
    }

    // const viewUserDetails = (item) => {
    //     var encId = encodeURIComponent(bodyEncryption((item.id).toString()))
    //     navigate(`/user/${encId}`);
    // }

    const showMessage = (msg) => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: msg,
            toast: true,
            showConfirmButton: false,
            timer: 1500
        })
    }

    function deleteUserIdWise(userId) {

        Swal.fire({
            title: 'Are you sure you want to delete?',
            text: "You won't be able to revert this!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#02C9D6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                // console.log("userId " + userId)
                deleteUser({ "user_id": userId }).then((response) => {
                    response = JSON.parse(response)
                    if (response.code === 200) {
                        // $('#mangeUser').DataTable().row("#userId" + userId).remove().draw(false);
                        showMessage(response.message);
                        // Update user list after deletion
                        setUsersList(prevUsersList => prevUsersList.filter(user => user.id !== userId));
                    }
                });
            }
        })

    }
    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    };
    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>
                            <div className="search-bar">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    value={searchQuery}
                                    onChange={handleSearch}
                                />
                            </div>
                            <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                <thead>
                                    <tr>
                                        <th >ID</th>
                                        <th >Name</th>
                                        <th >Email</th>
                                        <th >Phone Number</th>
                                        <th>Role</th>
                                        <th >Status</th>
                                        <th >Action</th>
                                    </tr>
                                </thead>

                                {usersList.length > 0 ? (
                                <tbody>
                                    {
                                        usersList && usersList.map((item, index) => {
                                            const displayIndex = ((pageNo * itemsPerPage) - 10) + index + 1;
                                            return (
                                                <tr id={item.id} key={index}>
                                                    <td >
                                                        {displayIndex}
                                                        {/* {item.id} */}
                                                    </td>
                                                    <td >
                                                        {item.firstname} {item.full_name}
                                                    </td>
                                                    <td >
                                                        {item.email}
                                                    </td>
                                                    <td >
                                                        {item.country_code + " " + item.phone_number}
                                                    </td>
                                                    <td >
                                                        {item.role}
                                                    </td>
                                                    <td >
                                                        {item.is_active === '0' ? <b><span style={{ "color": "Red" }}>Inactive</span></b> : <b><span style={{ "color": "Green" }}>Active</span></b>}
                                                    </td>
                                                    <td >
                                                        {/* <Tooltip title="View">
                                                            <IconButton onClick={() => { viewUserDetails(item) }}>
                                                                <FeatherIcon icon="message-square" width="18" />
                                                            </IconButton>
                                                        </Tooltip> */}

                                                        {/* <Tooltip title="Feedback">
                                                            <IconButton onClick={() => { viewFeedbackDetails(item) }}>
                                                                <FeatherIcon icon="eye" width="18" />
                                                            </IconButton>
                                                        </Tooltip> */}

                                                        <Tooltip title="Delete">
                                                            <IconButton onClick={() => { deleteUserIdWise(item.id) }}>
                                                                <FeatherIcon icon="trash-2" width="18" color="red" />
                                                            </IconButton>
                                                        </Tooltip>

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                ) : (
                                    usersList.length === 0 && <h3 className='mt-3'>No User data available.</h3>                                )}
                                
                            </table>
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=" > "
                                onPageChange={handlePageClick}
                                // pageRangeDisplayed={pageCount}
                                pageCount={totalRecordCount}
                                previousLabel=" < "
                                renderOnZeroPageCount={null}
                                containerClassName="pagination"
                                pageLinkClassName="page-link"
                                previousLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                disabledClassName="disabled"
                                activeClassName="active"
                                activeLinkClassName="active-link"
                            />
                        </div>
                    </div>
                </div>
            </div >


        </>

    );
}

export default UsersList;