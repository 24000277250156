import React,{ useEffect, useState } from 'react';
import { getCategoryList,addEditCategory,deleteCategory } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";

import FeatherIcon from "feather-icons-react";
// import Tooltip from '@material-ui/core/Tooltip';
import Tooltip from '@mui/material/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
import IconButton from '@mui/material/IconButton';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AWS from 'aws-sdk';

function Category() {
    const navigate = useNavigate();
    const [categoryList, setCategoryList] = useState([]);
    const [totalRecordCount, setTotalRecordCount] = useState(0)
    const [pageNo, setPageNo] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [loader, setLoader] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [categoryData,setCategoryData] = useState("");
    const [uploadImage, setUploadImage] = useState(null);

    useEffect(() => {
        if(searchQuery === ''){
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading();
                }
            });
        }

        getCategoryListFuncation()

        // Swal.close();
    }, [searchQuery, pageNo]);

    const getCategoryListFuncation = () => {
        getCategoryList({ 'search_text': searchQuery }).then((response) => {
            response = JSON.parse(response)
            // console.log('response: ', response);
            
            if (response.code === 200) {
                // console.log(response)
                Swal.close();
                setLoader(false);
                setCategoryList(response.data)
                // setTotalRecordCount(Math.ceil(response.data.total_record_count / process.env.REACT_APP_RECORD_COUNT))
            } else if (response.code === 404) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setCategoryList([]);
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setCategoryList([]);
            }
        }).catch((error)=>{
            console.log('error: ', error);
            if(error.response.status == 404){
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: 'No data found',
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setCategoryList([]);
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Something went wrong',
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setCategoryList([]);
            }
        })
    }

    const handlePageClick = (event) => {
        setPageNo(event.selected + 1)
    };
    
    const editCategory = (category_data) => {
        setCategoryData(category_data)
        // setValue2('category_name', category_data.name);
        setValue2('category_name',category_data.name,{shouldValidate : false})
        setValue2('category_image',category_data.category_image,{shouldValidate : false})
        setValue2('category_url',category_data.category_image_url,{shouldValidate : false})
        handleOpenEditModal()
    }

    const deleteCategoryFuncation = (category_id) => {
        // console.log('category_id: ', category_id);

        Swal.fire({
            title: 'Are you sure you want to delete?',
            text: "You won't be able to revert this!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#02C9D6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Please wait...',
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
        
                deleteParticularCategory(category_id)
            }
        })
    }

    const deleteParticularCategory = (category_id) => {
    
        const categoryObj = {
            category_id : category_id
        };

        deleteCategory(categoryObj).then((response) => {
            response = JSON.parse(response)
            // console.log('response: ', response);
            
            if (response.code === 200) {
                // console.log(response)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setTimeout(()=>{
                    getCategoryListFuncation()
                },500)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
            }
        })
    }

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenEditModal = () => {
        setShowEditModal(true);
    };

    const handleCloseEditModal = () => {
        setShowEditModal(false);
    };

     // upload image
     const uploadImageData = async (file,file_path) => {
        const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
        const REGION = process.env.REACT_APP_REGION;       

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            region: REGION,
          });
          const ext = file.name.split('.').pop()
          const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
          });

        try {
            const newFileName = new Date().getTime() + '.'+ext;
            const params = {
                Bucket: S3_BUCKET + file_path,
                Key: newFileName,
                Body: file,
                ACL: "public-read",
            };

            return new Promise((resolve, reject) => {
                s3.putObject(params, (err, data) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(newFileName);
                    }
                });
            });
        } catch (error) {
            console.error("Error in UploadImageData:", error);
        }
    };

    const validationSchema = Yup.object().shape({
        category_name: Yup.string()
            .required("Please enter category name"),
            // .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field')
            // .max(128, "Category name must be at most 128 character"),
        category_image: Yup.mixed()
            .test(
                'fileRequired',
                'Please select an image file',
                (value) => {
                    if (value && value.length > 0) {
                        return true;
                    }
                    return false;
                }
            )
            .test('fileType', 'Only image files are allowed', (value) => {
                if (!value || !value[0]) return true;
                return value[0].type.startsWith('image/');
            }),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, reset } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit1 = async (data) => {
        if (uploadImage != null) {
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            await uploadImageData(uploadImage,"/category").then((imagedata)=>{
                Swal.close()
                // console.log('imagedata: ', imagedata);
                data.category_image = imagedata;
                setUploadImage(null)

            }).catch((e)=>{
                Swal.close()
            });
        }
        // console.log('data: ', data);
        await addEditCategoryFunction("add",data)
        reset()
        handleCloseModal()
    }

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    };

    const addEditCategoryFunction = async (type,data) => {
    
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading();
            }
        });

        let categoryObj;
        if(type === "add"){
            categoryObj = {
                type : "add",
                category_name : data.category_name,
                category_image : data.category_image
            }   
        } 
        
        if(type === "edit"){
            categoryObj = {
                type : "edit",
                category_id : categoryData.id,
                category_name : data.category_name,
                category_image : data.category_image
            }
        }
        // console.log('categoryObj: ', categoryObj);

        addEditCategory(categoryObj).then((response) => {
            response = JSON.parse(response)
            // console.log('response: ', response);
            
            if (response.code === 200) {
                // console.log(response)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setTimeout(()=>{
                    getCategoryListFuncation()
                },500)
            } else if (response.code === 404) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
            } else if (response.code === 409) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
            }
        })
    }

    const validationSchema2 = Yup.object().shape({
        category_name: Yup.string()
            .required("Please enter category name"),
            // .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field')
            // .max(128, "Category name must be at most 128 character"),
        // category_image: Yup.mixed()
        //     .test(
        //         'fileRequired',
        //         'Please select an image file',
        //         (value) => {
        //             if (value && value.length > 0) {
        //                 return true;
        //             }
        //             return false;
        //         }
        //     )
        //     .test('fileType', 'Only image files are allowed', (value) => {
        //         if (!value || !value[0]) return true;
        //         return value[0].type.startsWith('image/');
        //     }),
    });

    const formOptions2 = { resolver: yupResolver(validationSchema2) };
    const { register : register2, setValue : setValue2, watch : watch2, handleSubmit : handleSubmit2, formState : formState2, reset : reset2 } = useForm(formOptions2);
    const { errors : errors2 } = formState2;    

    const onSubmit2 = async (data) => {
        if (uploadImage != null) {
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            await uploadImageData(uploadImage,"/category").then((imagedata)=>{
                Swal.close()
                // console.log('imagedata: ', imagedata);
                data.category_image = imagedata;
                setUploadImage(null)

            }).catch((e)=>{
                Swal.close()
            });
        }
        // console.log('data: ', data);
        await addEditCategoryFunction("edit",data)
        setCategoryData("")
        reset2()
        handleCloseEditModal()
    }

    return (
        <>
            <main>
                <div className='content-page mt-3'>
                    <div className='content'>
                        <div className='container-fluid'>
                            <div className='cal'>
                                <div className="col-sm-12">
                                    <div className="page-title-box d-flex justify-content-between">
                                        <h4 className="page-title">All Categories</h4>
                                        <i className="fa-solid fa-circle-plus plus-icon" onClick={handleOpenModal}></i>
                                    </div>
                                </div>
                                <div className="search-bar">
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchQuery}
                                        onChange={handleSearch}
                                    />
                                </div>
                                {loader === false ?
                                <>
                                    <div className="table-responsive">
                                        <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Name</th>
                                                    {/* <th>Status</th>
                                                    <th>Status Action</th> */}
                                                    <th>Action</th>
                                                </tr>
                                            </thead>        
                                            {categoryList.length > 0 ? (
                                            <tbody>
                                                {
                                                    categoryList && categoryList.map((item, index) => {
                                                        return (
                                                            <tr id={item.id} key={index}>
                                                                <td >
                                                                    {index+1}
                                                                </td>
                                                                <td >
                                                                    {item.name}
                                                                </td>
                                                                {/* <td id={"userStatus" + item.id}>
                                                                    {renderStatusUiText(item)}
                                                                </td> */}
                                                                {/* <td id={"userStatus" + item.id}>
                                                                    <Tooltip title="Change Status">
                                                                        <IconButton onClick={() => { changeStatus(item.id, item, index) }}>
                                                                            <FeatherIcon icon="repeat" width="18" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </td> */}
                                                                <td >
                                                                    <Tooltip title="Edit">
                                                                        <IconButton onClick={() => { editCategory(item) }}>
                                                                            <FeatherIcon icon="edit" width="18" color="blue" />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    <Tooltip title="Delete">
                                                                        <IconButton onClick={() => { deleteCategoryFuncation(item.id) }}>
                                                                            <FeatherIcon icon="trash-2" width="18" color="red" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            ) : (
                                                categoryList.length == 0 && <h3 className='mt-3'>No category data available.</h3>                                )}
                                            
                                        </table>
                                    </div>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=" > "
                                        onPageChange={handlePageClick}
                                        // pageRangeDisplayed={pageCount}
                                        pageCount={totalRecordCount}
                                        previousLabel=" < "
                                        renderOnZeroPageCount={null}
                                        containerClassName="pagination"
                                        pageLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        disabledClassName="disabled"
                                        activeClassName="active"
                                        activeLinkClassName="active-link"
                                    />
                                </>
                                :''}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header>
                    <Modal.Title>Add Category</Modal.Title>
                    <Tooltip title="Close">
                        <IconButton onClick={handleCloseModal}>
                            <FeatherIcon icon="x-square" width="35" />
                        </IconButton>
                    </Tooltip>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit1)}>
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Name
                        </label>
                        <input
                        type="text"
                        {...register('category_name')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter category"
                        />
                        <div className="invalid-feedback">{errors.category_name?.message}</div>
                    </div>
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Image
                        </label>
                        <input
                        type="file"
                        accept="image/*"
                        {...register('category_image')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter e.g., xyz"
                        onChange={(e)=>setUploadImage(e.target.files[0])}
                        />
                        <div className="invalid-feedback">{errors.category_image?.message}</div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button
                            type="submit"
                            id="buttonclose"
                            className="btn btn-primary"
                        >
                            Submit
                        </button>
                    </div>    
                        
                </form>
                </Modal.Body>
            </Modal>
            <Modal show={showEditModal} onHide={handleCloseEditModal} centered>
                <Modal.Header>
                    <Modal.Title>Edit Category</Modal.Title>
                    <Tooltip title="Close">
                        <IconButton onClick={handleCloseEditModal}>
                            <FeatherIcon icon="x-square" width="35" />
                        </IconButton>
                    </Tooltip>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={handleSubmit2(onSubmit2)}>
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Name
                        </label>
                        <input
                        type="text"
                        {...register2('category_name')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter category"
                        // defaultValue={categoryData != "" ? categoryData.name : ""}
                        />
                        <div className="invalid-feedback">{errors2.category_name?.message}</div>
                    </div>
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Image
                        </label>
                        <input
                        type="file"
                        accept="image/*"
                        {...register2('category_image')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter e.g., xyz"
                        onChange={(e)=>setUploadImage(e.target.files[0])}
                        />
                        <div className="invalid-feedback">{errors2.category_image?.message}</div>
                    </div>
                    <div className="mb-3">
                        <a href={watch2('category_url')} {...register2('category_url')} target="_blank" className="text-primary" >View Image</a>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button
                            type="submit"
                            id="buttonclose"
                            className="btn btn-primary"
                        >
                            Update
                        </button>
                    </div>    
                        
                </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Category;