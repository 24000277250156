// function AddIndustrail() {
//   return (
//     <>
//       <main>
//         <div className="content-page">
//           <div className="content">
//             <div className="container-fluid">
//               <div>
//                 <div className="row">
//                   <div className="col-sm-12">
//                     <div className="page-title-box">
//                       <h4 className="page-title">Industrial Add</h4>
//                     </div>
//                   </div>
//                 </div>

//                 <h1>Industrail add</h1>
//                 {/* <div className="row">
//                     <div className="col-xl-3 col-md-6">
//                         <div className="card mini-stat bg-primary">
//                             <div className="card-body mini-stat-img">
//                                 <div className="mini-stat-icon">
//                                     <i className="mdi mdi-cube-outline float-right"></i>
//                                 </div>
//                                 <div className="text-white">
//                                     <h6 className="text-uppercase mb-3">Total Setter</h6>
//                                     <h4 className="mb-4">0</h4>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>     
//                 </div> */}
//               </div>
//             </div>
//           </div>
//         </div>
//       </main>
//     </>
//   );
// }

// export default AddIndustrail;


import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { addIndustry, editIndustry, getIndustrylist } from "../../api/apiHandler";
import Swal from "sweetalert2";
import IndustrySetting from "./IndustrySetting";
import { Watch } from "feather-icons-react/build/IconComponents";
import { bodyDecryption } from "../common/RedirectPathMange";
import AWS from "aws-sdk";

const AddIndustrail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [step, setStep] = useState(1);
  const [industrysList, setIndustryList] = useState([]);
  const [pageNo, setPageNo] = useState(1);

  const { id: settingId } = useParams();

    let decSettingId = bodyDecryption(decodeURIComponent(settingId));

    useEffect(() => {

        // Swal.fire({
        //     title: 'Please wait...',
        //     didOpen: () => {
        //         Swal.showLoading();
        //     }
        // });

        getIndustrylist({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_INDUSTRY_RECORD_COUNT) }).then((response) => {
            response = JSON.parse(response)

            if (response.code === 200) {
                setIndustryList(response.data.Industry)
            } else {
                setIndustryList([]);
            }
        })
    }, [pageNo]);

    const uploadData = async (file) => {
      if (!file || !file.name) {
        throw new Error("Invalid file");
      }

      const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
      const REGION = process.env.REACT_APP_REGION;
  
      AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: REGION,
      });

      const ext = file.name.split('.').pop()
      // console.log('ext: ', ext);

      const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
      });
  
      try {
          const newFileName = new Date().getTime() + '.' + ext;
          const industryParams = {
              Bucket: S3_BUCKET + "/industry-images",
              Key: newFileName,
              Body: file,
              ACL: "public-read",
          };
        
          const leadParams = {
              Bucket: S3_BUCKET + "/lead-image",
              Key: newFileName,
              Body: file,
              ACL: "public-read",
          };
      
          // Promise to handle both uploads concurrently
          return new Promise((resolve, reject) => {
              // Upload to /industry-images folder
              s3.putObject(industryParams, (industryErr, industryData) => {
                  if (industryErr) {
                      reject(industryErr);
                  } else {
                      // Upload to /lead-image folder
                      s3.putObject(leadParams, (leadErr, leadData) => {
                          if (leadErr) {
                              reject(leadErr);
                          } else {
                              resolve(newFileName);  // Resolve with the file name after both uploads succeed
                          }
                      });
                  }
              });
          });

          // const newFileName = new Date().getTime() + '.' + ext;
          // const params = {
          //     Bucket: S3_BUCKET + "/industry-images",
          //     Key: newFileName,
          //     Body: file,
          //     ACL: "public-read",
          // };
  
          // return new Promise((resolve, reject) => {
          //     s3.putObject(params, (err, data) => {
          //         if (err) {
          //             reject(err);
          //         } else {
          //           // console.log('******* newFileName *******: ', newFileName);
          //             resolve(newFileName);
          //         }
          //     });
          // });
      } catch (error) {
          console.error("Error in UploadData:", error);
      }
    };
    

  const onSubmit = async (data) => {
    data.industry_id = decSettingId;
    try {
      if(typeof data.industry_image === "object"){
        Swal.fire({
          title: 'Please wait...',
          didOpen: () => {
            Swal.showLoading()
          }
        })
        await uploadData(data.industry_image).then((newImage)=>{
          Swal.close()
          data.industry_image = newImage
          // setValue("industry_image", newImage);
        }).catch((e)=>{
          console.log("error----------",e);
          Swal.close()
          
        });
      }
      const { code, message } = JSON.parse(settingId
        ? await editIndustry(data)
        : await addIndustry(data));

      if (code == 200) {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: message,
            toast: true,
            showConfirmButton: false,
            timer: 2000
        })
        setTimeout(() => {
            navigate('/industrailList');
        }, 2000)
      } else {
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: message,
            toast: true,
            showConfirmButton: false,
            timer: 2000
        })
      } 
    } catch (err) {
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: err.message,
            toast: true,
            showConfirmButton: false,
            timer: 2000
        })
    }
  };


  const isView = location.pathname.split("/")[1] === "view-industrail";
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <IndustrySetting
            onSubmit={onSubmit}
            appSettings={industrysList}
            isView={isView}
          />
        );
        default:
          return null;
        }
      };
      
  return (
    <div className="content">
      <div className="container-fluid g-5">
        {renderStep()}
      </div>
    </div>
  );
};

export default AddIndustrail;


