import React,{useEffect, useState, useRef} from 'react';
import { useNavigate,useLocation, Link } from 'react-router-dom';
import { auth,db } from "../../config/firebase";
import { collection,query,where,getDocs,getDoc,orderBy,onSnapshot,addDoc,setDoc,doc,updateDoc,serverTimestamp,limit} from "firebase/firestore";
import Swal from 'sweetalert2';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import AWS from 'aws-sdk';
import { useChatCount } from '../context/total_count';
import { adminChatNotificationStore } from '../../api/apiHandler';

function ChatPage() {
    const navigate = useNavigate();
    const location = useLocation();

    const { totalChatCount,setTotalChatCount } = useChatCount();
    // console.log('totalChatCount: ', totalChatCount);
    const [chatUserData,setChatUserData] = useState(location.state != null ? location.state : "")
    // console.log('chatUserData: ', chatUserData);
    const user_receiver_id = location.state.receiver_id;

    const [recentChatId,setRecentChatId] = useState(location.state != null ? `@admin@${location.state.receiver_id}@` : "");
    const [isActive,setIsActive] = useState(location.state != null ? location.state.isActive : false);
    
    const [userChatHistory,setUserChatHistory] = useState("");
    // console.log('userChatHistory: ', userChatHistory);
    
    const [message, setMessage] = useState("");

    const [showModal, setShowModal] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState("");

    const messagesEndRef = useRef(null);
    
    useEffect(() => {
        getUserChatHistory(recentChatId)
        updateChatReadStatus(recentChatId,"A1")
    }, []);
    
    useEffect(() => {
        updateChatCountAndReadStatus(recentChatId)
    },[message,userChatHistory])

    useEffect(() => {
        scrollToBottom();
    },[userChatHistory])

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const getUserChatHistory = (recentChatId) => {
        const q = query(collection(db, process.env.REACT_APP_FIREBASE_TABLE_CHAT),where("recent_chat_id", "==", recentChatId),orderBy("timestamp", "asc"));            
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const fetchedRecentChatList = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                data.id = doc.id;
                data.chat_time = formatDateTime(data.timestamp);
                fetchedRecentChatList.push(data);
                // fetchedRecentChatList.push({ ...doc.data(), id: doc.id });
            });
            setUserChatHistory(fetchedRecentChatList);
        });

        return () => unsubscribe();
    };

    const updateChatReadStatus = (recentChatId, receiverId) => {
        const q = query(collection(db, process.env.REACT_APP_FIREBASE_TABLE_CHAT),where("recent_chat_id", "==", recentChatId),where("receiver_id", "==", receiverId),where("chat_read", "==", false),orderBy("timestamp", "asc"));
    
        return new Promise((resolve, reject) => {
            onSnapshot(q, async (querySnapshot) => {
                const batchUpdates = [];
    
                querySnapshot.forEach((docSnapshot) => {
                    const chatRef = doc(db, process.env.REACT_APP_FIREBASE_TABLE_CHAT, docSnapshot.id);
                    batchUpdates.push(updateDoc(chatRef, { chat_read: true }));
                });
    
                try {
                    await Promise.all(batchUpdates);
                    // await calculateTotalChatCount()
                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        });
    };

    const updateChatCountAndReadStatus = async (recentChatId) => {
        try {
            if(isActive){
                const recentChatDocRef = doc(db, process.env.REACT_APP_FIREBASE_TABLE_RECENT_CHAT, recentChatId);
                const recentChatDoc = await getDoc(recentChatDocRef);
    
                if (recentChatDoc.exists()) {
                    const recentChatData = recentChatDoc.data();
                    // console.log('recentChatData: ', recentChatData);
    
                    if (recentChatData.receiver_id === "A1") {
                        await updateDoc(recentChatDocRef, {
                            chat_count: "0",
                            chat_read: true
                        });
                        // await calculateTotalChatCount()
                    }
                } else {
                    console.log("No such document!");
                    Swal.fire({
                        position: 'top-end',
                        icon: 'info',
                        title: 'No data found!',
                        toast: true,
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
                setIsActive(false)
            }
        } catch (error) {
            console.log('Error updating chat count and read status: ', error);
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: error.message,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        }
    };

    const formatDateTime = (timestamp) => {
        if (!timestamp) return "";
        const date = moment(timestamp.toDate());
        const now = moment();
    
        if (date.isSame(now, 'day')) {
            return `Today at ${date.format('hh:mm A')}`;
        } else if (date.isSame(now.clone().subtract(1, 'day'), 'day')) {
            return `Yesterday at ${date.format('hh:mm A')}`;
        } else {
            return date.format('D MMM YYYY hh:mm A');
        }
    };

    const uploadImage = async (file,folder_type) => {
        const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
        const REGION = process.env.REACT_APP_REGION;

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            region: REGION,
          });
          const ext = file.name.split('.').pop()
          const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
          });

        try {
            const newFileName = new Date().getTime() + '.'+ext;
            const params = {
                Bucket: S3_BUCKET + folder_type,
                Key: newFileName,
                Body: file,
                ACL: "public-read",
            };
            return new Promise((resolve, reject) => {
                s3.putObject(params, (err, data) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(newFileName);
                    }
                });
            });
        } catch (error) {
            console.error("Error in UploadData:", error);
        }
    };
    
    const sendMessage = async (message_type,image_file) => {
        
        try {
            const sendMessageObj = {
                timestamp: serverTimestamp(),
                participants: [chatUserData.receiver_id,"A1"],
                media: message_type === "image" ? `${process.env.REACT_APP_CHAT_IMAGES_AWS_S3_URL}${recentChatId}/${image_file}` : "",
                message: message_type === "normal" ? message : "",
                message_type: message_type,
                sender_device_token: "",
                sender_username: "Admin",
                sender_image: "",
                sender_device_type: "",
                sender_lastname: "Admin",
                sender_firstname: "Admin",
                sender_id: "A1",
                receiver_username: chatUserData.receiver_username,
                receiver_id: chatUserData.receiver_id,
                recent_chat_id: recentChatId,
                receiver_firstname: chatUserData.receiver_firstname,
                receiver_image: chatUserData.receiver_image,
                receiver_lastname: chatUserData.receiver_lastname,
                chat_time: "",
                chat_read: false
            }

            await addDoc(collection(db, process.env.REACT_APP_FIREBASE_TABLE_CHAT), sendMessageObj);
            setMessage("");

            const q = query(
                collection(db, process.env.REACT_APP_FIREBASE_TABLE_CHAT),
                where("recent_chat_id", "==", recentChatId),
                where("chat_read", "==", false),
                where("receiver_id", "==", chatUserData.receiver_id)
            );
    
            const querySnapshot = await getDocs(q);
            const unreadCount = querySnapshot.size;

            const lastMessageDocRef = doc(db, process.env.REACT_APP_FIREBASE_TABLE_RECENT_CHAT, recentChatId);
            sendMessageObj.chat_count = unreadCount.toString();
            // console.log('sendMessageObj: ', sendMessageObj);
            await setDoc(lastMessageDocRef, sendMessageObj);

            // adminChatNotificationStore(parseInt(user_receiver_id));

            adminChatNotificationStore({ "user_receiver_id": user_receiver_id }).then((response) => {
                response = JSON.parse(response)
            }).catch((error)=>{
                console.log('error: ', error);
            })

        } catch (error) {
            console.log('error: ', error);
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: error.message,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
          }
    }

    const calculateTotalChatCount = async () => {
        try {
            const recentChatCollection = collection(db, process.env.REACT_APP_FIREBASE_TABLE_RECENT_CHAT);
            const recentChatSnapshot = await getDocs(recentChatCollection);

            let totalCount = 0;
            recentChatSnapshot.forEach(doc => {
                const data = doc.data();
                if (data.receiver_id === "A1") {
                    totalCount += parseInt(data.chat_count, 10) || 0;
                }
            });
            // console.log('totalCount: ', totalCount);

            setTotalChatCount(totalCount);
        } catch (error) {
            console.log('Error calculating total chat count: ', error);
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: error.message,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        }
    };

    const handleImageClick = (imageSrc) => {
        setModalImageSrc(imageSrc);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalImageSrc("");
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && message.trim() !== '') {
          sendMessage("normal","");
        }
    };

    const sendImage = async (e) => {
        if (e.target.files[0]) {
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            await uploadImage(e.target.files[0],`/chat/${recentChatId}`).then((newImage)=>{
                Swal.close()
                // console.log('newImagenewImagenewImage',newImage)
                sendMessage("image",newImage)
                
            }).catch((e)=>{
                Swal.close()
                
            });
        }
    }

    if(recentChatId == null && chatUserData == null) return <div className="container"><div className="mt-5"></div></div>

    return (
        <>
            <div className='content-page mt-2'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>
                            <div className="row clearfix d-flex justify-content-center text-center" >
                                {/* <h3 className="text-dark">Chat view</h3>   */}
                                <div className="chatbody">
                                    <div className="chat-main">
                                        <section className="Chat">
                                            <div className="ChatHead">
                                                <div className="d-block d-lg-none">
                                                    <Link to="j#" className=" user-chat-remove">
                                                        <i className="fa-solid fa-angle-left"></i>
                                                    </Link>
                                                </div>
                                                <li className="group ">
                                                    <Link to='/chatuserlist' className="common_hover" style={{ fontSize: '24px' }}>
                                                        <i className="mdi mdi-chevron-left"></i>
                                                    </Link>
                                                    <img src={chatUserData.receiver_image} className="avatar user-chat-remove ml-3 text-secondary" alt="userimage" />
                                                    <p className="GroupName">{chatUserData.receiver_firstname +" "+chatUserData.receiver_lastname}</p>
                                                </li>
                                            </div>

                                            

                                            <div className="MessageContainer" id="chatElement">
                                                <span></span>
                                                {userChatHistory && userChatHistory.map((chat)=>{
                                                    return(
                                                        <div className={`message ${chat.receiver_id === "A1" ? 'you' : 'me'}`} key={chat.id}>
                                                            {chat.message_type === "normal" 
                                                            ? <p className="messageContent">{chat.message}</p>
                                                            : <img src={chat.media} alt="image" style={{height:"120px",width:"120px",cursor:"pointer"}} onClick={() => handleImageClick(chat.media)} />
                                                            }
                                                            <div className="messageDetails">
                                                                <div className="messageTime">{chat.chat_time}</div>
                                                                {/* <i className="fa-solid fa-check"></i> */}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                <div ref={messagesEndRef} />
                                                {/* <div className="message me">
                                                    <p className="messageContent">How are You!</p>
                                                    <div className="messageDetails">
                                                        <div className="messageTime">3:22 PM</div>
                                                        <i className="fa-solid fa-check"></i>
                                                    </div>
                                                </div>
                                                <div className="message you">
                                                    <p className="messageContent">I'm Fine!</p>
                                                    <div className="messageDetails">
                                                        <div className="messageTime">3:30 PM</div>
                                                        <i className="fa-solid fa-check"></i>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div id="MessageForm">
                                                <div className="chat-input-group">
                                                    <input type="text" placeholder="Enter Message..." id="MessageInput" value={message} onChange={(e)=>setMessage(e.target.value)} onKeyDown={handleKeyDown} />
                                                    <span className="input-group-text p-0" id="basic-addon2">
                                                        <label for="formFile" className="form-label mb-0" style={{cursor:"pointer"}}> 
                                                        <i className="fa-solid fa-paperclip font_18" style={{cursor:"pointer"}}></i></label>
                                                        <input className="form-control d-none" type="file" id="formFile" accept="image/*" onChange={(e)=>sendImage(e)} />
                                                    </span>
                                                </div>
                                                <button type="submit" className="Send" onClick={()=>sendMessage("normal","")} disabled={message.trim() === ''}><i className="fa-solid fa-paper-plane"></i></button>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Body>
                    <img src={modalImageSrc} alt="Full Screen" className="img-fluid" />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ChatPage;