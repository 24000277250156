import React, { useEffect, useState, useRef } from 'react'
import { chargeBuyerApi, cancelTransactionApi, getLeadList } from '../../api/apiHandler';
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Swal from 'sweetalert2'

import { Link, useNavigate, useParams } from 'react-router-dom';
import { bodyDecryption } from '../common/RedirectPathMange';
import moment from 'moment';

import { Modal } from 'react-bootstrap';


function LeadView() {
    let { id } = useParams();
    var decId = decodeURIComponent(bodyDecryption(id))
    const navigate = useNavigate();

    // const downloadRef = useRef(null);
    const downloadRefs = useRef(null);
    const [pageNo, setPageNo] = useState(1);
    const [leadDetails, setLeadDetails] = useState(null)
    const [loader, setLoader] = useState(true);
    const [selectedDisposition, setSelectedDisposition] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState("");

    useEffect(() => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading();
            }
        });
        if (id != undefined) {
            getLeadList({ "lead_id": decId,"page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT) }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    Swal.close();
                    setLoader(false);
                    setLeadDetails(res.data.Leads[0])
                    setSelectedDisposition(res.data.Leads[0].disposition);
                } else {
                    Swal.close();
                    setLoader(false);
                    setLeadDetails([])
                }
            });
        }
    }, [id])

   

    function handleImageDownload (image) {
        let img_url = `${process.env.REACT_APP_AWS_S3_URL}` + `${process.env.REACT_APP_LEAD_IMAGES}` + '/' + image;
        // console.log('img_url: ', img_url);
        window.open(img_url, '_blank');
    
    }

    const handleImageClick = (imageSrc) => {
        setModalImageSrc(imageSrc);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalImageSrc("");
    };

    const [currentAudio, setCurrentAudio] = useState(null);
    const audioRef = useRef(null);

    const handlePlay = (audioElement) => {
        if (currentAudio && currentAudio !== audioElement) {
            currentAudio.pause();
        }
        setCurrentAudio(audioElement);
    };
    
    if (leadDetails == null) return <> </>

    return (
        <>
            <div className='content-page mt-2'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>
                            <div className="row clearfix d-flex justify-content-center text-center" >
                                {loader === false ?
                                <div className="col-lg-9 col-md-12">
                                    <h2>Lead Details</h2>

                                    <div className="body">
                                        <hr />
                                        <div className="row">
                                            <div className="col-4">
                                                <h5>Industry</h5>                                                
                                                {leadDetails.industry == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                        <>
                                                        <p style={{ fontSize: '15px' }}>{leadDetails.industry}</p>
                                                    </>
                                                )}

                                            </div>
                                            <div className="col-4">
                                                <h5>Category</h5>
                                                <p style={{ fontSize: '15px' }}> {leadDetails.category == 'Verified' ? "Approved" : "Pending"}</p>
                                            </div>
                                            <div className="col-4">
                                                <h5>Name</h5>
                                                {leadDetails.name == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                        <>
                                                        <p style={{ fontSize: '15px' }}>{leadDetails.name}</p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-4">
                                                <h5>Email</h5>
                                                {leadDetails.email == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                        <>
                                                        <p style={{ fontSize: '15px' }}>{leadDetails.email}</p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-4">
                                                <h5>Phone Number</h5>
                                                {leadDetails.phone_number == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                        <>
                                                        <p style={{ fontSize: '15px' }}>{leadDetails.phone_number}</p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-4">
                                                <h5>Secondary Phone Number</h5>
                                                {leadDetails.secondary_phone_number ? (
                                                    <p style={{ fontSize: '15px' }}>{leadDetails.secondary_phone_number}</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>No data</p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-4">
                                                <h5>Is Virtual</h5>
                                                {/* <p style={{ fontSize: '15px' }}>{leadDetails.is_virtual}</p> */}
                                                <p style={{ fontSize: '15px' }}>{leadDetails.is_virtual == '0' ? "Not-virtual" : "Virtual"}</p>
                                            </div>
                                            <div className="col-4">
                                                <h5>Appointment Date & Time</h5>
                                                <p style={{ fontSize: '15px' }}>{ moment(leadDetails.appointment_date).local().format('YYYY-MM-DD hh:mm A') }</p>
                                            </div>
                                           
                                            <div className="col-4">
                                                <h5>Lead Status</h5>
                                                {leadDetails.status == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>{leadDetails.status}</p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-4">
                                                <h5>Lead Main Status</h5>
                                                {leadDetails.lead_main_status == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>{leadDetails.lead_main_status}</p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-4">
                                                <h5>Priority</h5>
                                                {leadDetails.priority ? (
                                                    <p style={{ fontSize: '15px' }}>{leadDetails.priority}</p>
                                                    ) : (
                                                        <>
                                                        <p style={{ fontSize: '15px' }}>No data</p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-4">
                                                <h5>Badges</h5>
                                                {leadDetails.badges == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                        <>
                                                        <p style={{ fontSize: '15px' }}>{leadDetails.badges}</p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        
                                        <hr />
                                        <div className="row">
                                            <div className="col-4">
                                                <h5>Address</h5>
                                                <p style={{ fontSize: '15px' }}>{leadDetails.address}</p>
                                            </div>
                                            <div className="col-4">
                                                <h5>Avg Rating</h5>
                                                {leadDetails.avg_rating == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>{leadDetails.avg_rating}</p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-4">
                                                <h5>Total Review</h5>
                                                {leadDetails.total_review == null ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <p style={{ fontSize: '15px' }}>{leadDetails.total_review}</p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <hr />

                                        <div className="row">
                                            <div className="col-4">
                                                <h5>Show Contact Info</h5>
                                                <p style={{ fontSize: '15px' }}>{leadDetails.is_showcontactinfo == 0 ? "No" : "Yes"}</p>
                                            </div>
                                            <div className="col-4">
                                                <h5>Status</h5>
                                                <p style={{ fontSize: '14px' }}>{leadDetails.is_active == '0' ? "🔴 Inactive" : "🟢 Active"}</p>
                                            </div>
                                            <div className="col-4">
                                                <h5>Created</h5>
                                                <p style={{ fontSize: '15px' }}>{ moment(leadDetails.createdAt).local().format('YYYY-MM-DD hh:mm A') }</p>
                                            </div>
                                        </div>
                                        <hr />
                                        

                                        <div className="row">
                                            <div className="col-12 text-left">
                                                <h5>Lead Notes</h5>
                                                {leadDetails.lead_notes_data.length == 0 ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <ul className="list-group">
                                                            {leadDetails.lead_notes_data.map((note, index) => (
                                                                <li key={index} className="list-group-item">
                                                                    <div className="note-content">
                                                                        <span className="note-index"><b>{index + 1}.</b></span>
                                                                            <span className="note-text mx-2" style={{ fontWeight: 'bold' }}>{note.title}</span>
                                                                            <div className='clearfix'></div>
                                                                            <span className="note-text mx-3">{note.notes}</span>
                                                                        </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 text-left">
                                                <h5>Lead Images</h5>
                                                {leadDetails.lead_images.length == 0 ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <ul className="list-group">
                                                            <div class="row">
                                                                {leadDetails.lead_images.map((image, index) => (
                                                                    <li key={index} className="list-group-item">
                                                                    <div class="col-4 note-content"> 
                                                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                                                            <img
                                                                                ref={downloadRefs}
                                                                                src={image.images ? `${process.env.REACT_APP_AWS_S3_URL}` + `${process.env.REACT_APP_LEAD_IMAGES}` + '/' + image.images : 'https://www.pngitem.com/pimgs/m/508-5087336_person-man-user-account-profile-employee-profile-template.png'}
                                                                                className=""
                                                                                alt="lead-image"
                                                                                style={{ width: 200, height: 200, cursor:'pointer' }}
                                                                                onClick={()=>handleImageClick(`${process.env.REACT_APP_AWS_S3_URL}` + `${process.env.REACT_APP_LEAD_IMAGES}` + '/' + image.images)}
                                                                            />
                                                                            <Tooltip title="Download" placement="top">
                                                                                <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={() => handleImageDownload(image.images)}>
                                                                                    <FeatherIcon icon="download" width="18" color="blue" />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </div>
                                                                    {/* <span>{image.images.split("/")[7]}</span> */}
                                                                    </li>
                                                                ))}
                                                            </div>
                                                        </ul>      
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 text-left">
                                                <h5>Lead Recordings</h5>
                                                {leadDetails.lead_recordings.length == 0 ? (
                                                    <p style={{ fontSize: '15px' }}>No data</p>
                                                    ) : (
                                                    <>
                                                        <ul className="list-group">
                                                            {leadDetails.lead_recordings.map((recording, index) => (
                                                                <li key={index} className="list-group-item">
                                                                    <div className="note-content">
                                                                        <div className='row'>
                                                                            <span className="note-index"><b>{index + 1}.</b></span>
                                                                            {/* <audio src={`${process.env.REACT_APP_AWS_S3_URL}` + `${process.env.REACT_APP_LEAD_RECORDINGS}` + '/' + recording.recordings_name} controls/> */}
                                                                            <audio
                                                                                ref={audioRef}
                                                                                src={`${process.env.REACT_APP_AWS_S3_URL}${process.env.REACT_APP_LEAD_RECORDINGS}/${recording.recordings_name}`}
                                                                                controls
                                                                                onPlay={(e) => handlePlay(e.target)}
                                                                            />
                                                                        </div>

                                                                        <div className='row'>
                                                                            {/* <span className="note-text mx-2">{recording.recordings_name.split("/")[4]}</span> */}
                                                                            <span> <strong>{recording.recording_show + " : "}</strong> </span>
                                                                            <span className="note-text mx-1">{recording.dummy_recording}</span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                    )}
                                            </div>
                                        </div>

                                       
                                        
                                        <br /><br />
                                    </div>

                                    <Link to='/earning-list' className="btn btn-primary btn-lg common_hover">
                                        <i className="mdi mdi-chevron-left"></i> <span> Back </span>
                                    </Link>
                                </div>
                                :''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Body>
                    <img src={modalImageSrc} alt="Full Screen" className="img-fluid" />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default LeadView;