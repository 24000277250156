import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { bodyDecryption, bodyEncryption } from '../common/RedirectPathMange';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllLeadData, getLeadList } from '../../api/apiHandler';

function UrlPage() {
    let { id } = useParams();
    var decId = decodeURIComponent(bodyDecryption(id))

    const [pageNo, setPageNo] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading();
            }
        });
        if (id != undefined) {

            getAllLeadData({ "lead_id": decId,"page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT) }).then((res) => {
                res = JSON.parse(res)
                var encId = encodeURIComponent(bodyEncryption((decId).toString()))

                if (res.code == 200) {
                    if(res.data.is_approve == 1){
                        Swal.close();
                        navigate(`/leadView/${encId}`);
                    } else {
                        Swal.close();
                        navigate(`/toBeVerifiedView/${encId}`);
                    }
                } else {
                    Swal.close();
                }
            });
        }
    }, [])
  
return (
    <div>
      
    </div>
  )
}

export default UrlPage;
