import CryptoJS from 'crypto-js';
var key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_API_ENC_KEY);
var iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_API_ENC_IV);

export function loginRedirectCall() {
  let path = window.location.protocol + "//" + window.location.host + "/admin"
  //    window.open(path,'mywindow').focus()
  window.location.href = path;
}
export function logOutRedirectCall() {
  localStorage.removeItem("AisLogin");
  localStorage.removeItem("Atoken");
  localStorage.removeItem("Aname");
  localStorage.removeItem("Alname");
  localStorage.removeItem("Aid");
  localStorage.removeItem("Aemail");
  localStorage.removeItem("Arole");
  loginRedirectCall()
}

export function loginStoreData(data) {
  localStorage.setItem("AisLogin", true);
  localStorage.setItem("Atoken", data.token);
  // localStorage.setItem("Aname", data.firstname);
  // localStorage.setItem("Alname", data.lastname);
  // localStorage.setItem("Aid", data.id);
  // localStorage.setItem("Aemail", data.email);
  // localStorage.setItem("Arole", data.role);
  loginRedirectCall()
}

export function bodyEncryption(request, isStringify) {
  // console.log("Encryption Request", request)

  var req = (isStringify) ? JSON.stringify(request) : request;
  var encrypted = CryptoJS.AES.encrypt(req, key, { iv: iv });
  return encrypted.toString();
}

export function bodyDecryption(request) {
  var decrypted = CryptoJS.AES.decrypt(request.toString(), key, { iv: iv });
  // console.log("Decrypt Data", decrypted)

  return decrypted.toString(CryptoJS.enc.Utf8);
}