import React,{ useEffect, useState } from 'react';
import { getRatingReviewList, approveRejectReviews } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";

import FeatherIcon from "feather-icons-react";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Swal from 'sweetalert2'
import ReactPaginate from 'react-paginate';
import { Modal } from 'react-bootstrap';

function LeadReviewList() {
    const [leadReviewList, setLeadReviewList] = useState([]);
    const [totalRecordCount, setTotalRecordCount] = useState(0)
    const [pageNo, setPageNo] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [loader, setLoader] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [leadReviewData,setLeadReviewData] = useState(null);

    useEffect(() => {

        if(searchQuery === ''){
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading();
                }
            });
        }

        getRatingReviewData()

        // Swal.close();
    }, [searchQuery, pageNo]);


    const getRatingReviewData = () => {
        getRatingReviewList({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery }).then((response) => {
            response = JSON.parse(response)
            
            if (response.code === 200) {
                Swal.close();
                setLoader(false);
                setLeadReviewList(response.data.lead_rating_review_list)
                setTotalRecordCount(Math.ceil(response.data.total_record_count / process.env.REACT_APP_RECORD_COUNT))
            } else if (response.code === 404) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setLeadReviewList([]);
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setLeadReviewList([]);
            }
        }).catch((error)=>{
            if(error.response.status === 404){
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: 'No data found',
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setLeadReviewList([]);
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Something went wrong',
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setLeadReviewList([]);
            }
        })
    };

    const handlePageClick = (event) => {
        setPageNo(event.selected + 1)
    };

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    }

    const handleOpenModal = () => {
        setShowModal(true);
    };
    
    const handleCloseModal = async () => {
        await setLeadReviewData(null)
        setShowModal(false);
    };
    
    const viewLeadReviewDetails = async (lead_review_data,index_value) => {
        lead_review_data.index_value = index_value;
        await setLeadReviewData(lead_review_data)
        handleOpenModal()
    }

    const changeStatus = (lead_review_data,status) => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading();
            }
        });

        const reviewObj = {
            review_id : lead_review_data.id,
            is_approved : parseInt(status)
        };

        approveRejectReviews(reviewObj).then((response) => {
            response = JSON.parse(response)
            // console.log('response: ', response);
            
            if (response.code === 200) {
                // console.log(response)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setTimeout(()=>{
                    getRatingReviewData()
                },500)
            } else {
                let type = 'error';
                if(response.message === "Rating review status already updated"){
                    type = 'warning';
                }
                Swal.fire({
                    position: 'top-end',
                    icon: type,
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
            }
        })
    }

    return (
        <>
            <main>
                <div className='content-page mt-3'>
                    <div className='content'>
                        <div className='container-fluid'>
                            <div className='cal'>
                                <div className="col-sm-12">
                                    <div className="page-title-box">
                                        <h4 className="page-title">Lead Review & Rating List</h4>
                                    </div>
                                </div>
                                <div className="search-bar">
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchQuery}
                                        onChange={handleSearch}
                                    />
                                </div>
                                {loader === false ?
                                <>
                                    <div className="table-responsive">
                                        <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Closer Name</th>
                                                    <th>Setter Name</th>
                                                    <th>Lead Title</th>
                                                    <th>Rating</th>
                                                    <th>Status Action</th> 
                                                    <th>Action</th>
                                                </tr>
                                            </thead>

                                            {leadReviewList.length > 0 ? (
                                            <tbody>
                                                {
                                                    leadReviewList && leadReviewList.map((item, index) => {
                                                        return (
                                                            <tr id={item.id} key={index}>
                                                                <td >
                                                                    {index+1}
                                                                </td>
                                                                {/* <td>
                                                                    <td>{item.industry && item.industry.length > 0 ? item.industry.map(ind => ind.industry_name).join(', ') : 'N/A'}</td>
                                                                </td> */}
                                                                <td >
                                                                    {item.closer_data.firstname+" "+item.closer_data.lastname}
                                                                </td>
                                                                <td>
                                                                    {item.lead_data.setter_data.firstname+" "+item.lead_data.setter_data.lastname}
                                                                    {/* {Array.isArray(item.industry) ? item.industry.map(ind => ind.industry_name).join(', ') : 'N/A'} */}
                                                                </td>
                                                                <td >
                                                                    {item.lead_data.name}
                                                                </td>
                                                                <td >
                                                                    {item.rating}
                                                                </td>
                                                                <td>
                                                                    {item.is_approved === 0 ? 
                                                                    <>
                                                                        <Tooltip title="Change Status">
                                                                            <IconButton onClick={() => { changeStatus(item,process.env.REACT_APP_LEAD_REVIEW_ACCEPT_STATUS) }}>
                                                                                <FeatherIcon icon="check" width="18" color="green" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title="Change Status">
                                                                            <IconButton onClick={() => { changeStatus(item,process.env.REACT_APP_LEAD_REVIEW_REJECT_STATUS) }}>
                                                                                <FeatherIcon icon="x" width="18" color="red" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </> : 
                                                                    <>
                                                                        {item.is_approved === 0 ? <b><span style={{ "color": "Blue" }}>Pending</span></b> : item.is_approved === 1 ?  <b><span style={{ "color": "Green" }}>Approved</span></b> : <b><span style={{ "color": "Red" }}>Rejected</span></b>}
                                                                    </>}
                                                                </td>
                                                                <td >
                                                                    <Tooltip title="View">
                                                                        <IconButton onClick={() => { viewLeadReviewDetails(item,index) }}>
                                                                            <FeatherIcon icon="eye" width="18" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                            </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            ) : (
                                                leadReviewList.length === 0 && <h3 className='mt-3'>No Lead reviews data available.</h3>                                )}
                                            
                                        </table>
                                    </div>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=" > "
                                        onPageChange={handlePageClick}
                                        // pageRangeDisplayed={pageCount}
                                        pageCount={totalRecordCount}
                                        previousLabel=" < "
                                        renderOnZeroPageCount={null}
                                        containerClassName="pagination"
                                        pageLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        disabledClassName="disabled"
                                        activeClassName="active"
                                        activeLinkClassName="active-link"
                                    />
                                </>
                                :''}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header>
                    <Modal.Title>Lead Review Details</Modal.Title>
                    <Tooltip title="Close">
                        <IconButton onClick={handleCloseModal}>
                            <FeatherIcon icon="x-square" width="35" />
                        </IconButton>
                    </Tooltip>
                </Modal.Header>
                <Modal.Body>
                <div className="mb-3">
                    <label htmlFor="question" className="font-weight-bold h6">
                        ID
                    </label>
                    <p id="question" className="form-label mt-2">
                        {leadReviewData && leadReviewData.index_value + 1}
                    </p>
                </div>
                <div className="mb-3">
                    <label htmlFor="question" className="font-weight-bold h6">
                        Closer Name
                    </label>
                    <p id="question" className="form-label mt-2">
                        {leadReviewData && leadReviewData.closer_data.firstname+" "+leadReviewData.closer_data.lastname}
                    </p>
                </div>
                <div className="mb-3">
                    <label htmlFor="question" className="font-weight-bold h6">
                        Setter Name
                    </label>
                    <p id="question" className="form-label mt-2">
                        {leadReviewData && leadReviewData.lead_data.setter_data.firstname+" "+leadReviewData.lead_data.setter_data.lastname}
                    </p>
                </div>
                <div className="mb-3">
                    <label htmlFor="question" className="font-weight-bold h6">
                        Lead Title
                    </label>
                    <p id="question" className="form-label mt-2">
                        {leadReviewData && leadReviewData.lead_data.name}
                    </p>
                </div>
                <div className="mb-3">
                    <label htmlFor="question" className="font-weight-bold h6">
                        Lead Rating
                    </label>
                    <p id="question" className="form-label mt-2">
                        {leadReviewData && leadReviewData.rating}
                    </p>
                </div>
                <div className="mb-3">
                    <label htmlFor="question" className="font-weight-bold h6">
                        Lead Review
                    </label>
                    <p id="question" className="form-label mt-2">
                        {leadReviewData && leadReviewData.review}
                    </p>
                </div>
                <div className="mb-3">
                    <label htmlFor="question" className="font-weight-bold h6">
                        Status
                    </label>
                    <p id="question" className="form-label mt-2">
                        {leadReviewData && leadReviewData.is_approved === 0 ? <b><span style={{ "color": "Blue" }}>Pending</span></b> : leadReviewData && leadReviewData.is_approved === 1 ?  <b><span style={{ "color": "Green" }}>Approved</span></b> : <b><span style={{ "color": "Red" }}>Rejected</span></b>}
                    </p>
                </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default LeadReviewList;
