import React, { useEffect, useState } from 'react'
import { deleteLead, changeLeadStatus, approveRejectLead, getInReviewsLeadList } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import CryptoJS from 'crypto-js';
import { bodyEncryption } from '../common/RedirectPathMange';
import $ from "jquery";

import FeatherIcon from "feather-icons-react";
// import Tooltip from '@material-ui/core/Tooltip';
import Tooltip from '@mui/material/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
import IconButton from '@mui/material/IconButton';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import ReactDOM from 'react-dom';

function InReviewList() {
    const navigate = useNavigate();
    const [leadsList, setLeadsList] = useState([]);
    const [totalRecordCount, setTotalRecordCount] = useState(0)
    const [pageNo, setPageNo] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [loader, setLoader] = useState(true);
    const itemsPerPage = 10;

    useEffect(() => {
        if(searchQuery === ''){
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading();
                }
            });
        }

        getInReviewsLeadList({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery }).then((response) => {
            response = JSON.parse(response)
            
            if (response.code === 200) {
                Swal.close();
                setLoader(false);
                setLeadsList(response.data.Leads)
                setTotalRecordCount(Math.ceil(response.data.total_record_count / process.env.REACT_APP_RECORD_COUNT))
            } else {
                Swal.close();
                setLoader(false);
                setLeadsList([]);
            }
        })

        // Swal.close();
    }, [searchQuery, pageNo]);


    const handlePageClick = (event) => {
        setPageNo(event.selected + 1)
    };

    const editLeadDetails = (item) => {
        var encId = encodeURIComponent(bodyEncryption((item.id).toString()))
        navigate(`/inReviewEdit/${encId}`);
    }

    const viewLeadDetails = (item) => {
        var encId = encodeURIComponent(bodyEncryption((item.id).toString()))
        navigate(`/inReviewView/${encId}`);
    }

    const showMessage = (msg) => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: msg,
            toast: true,
            showConfirmButton: false,
            timer: 1500
        })
    }

    function deleteLeadIdWise(leadId) {

        Swal.fire({
            title: 'Are you sure you want to delete?',
            text: "You won't be able to revert this!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#02C9D6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteLead({ "lead_id": leadId }).then((response) => {
                    response = JSON.parse(response)
                    if (response.code === 200) {
                        // $('#mangeUser').DataTable().row("#userId" + userId).remove().draw(false);
                        showMessage(response.message);
                        // Update user list after deletion
                        setLeadsList(prevUsersList => prevUsersList.filter(lead => lead.id !== leadId));
                    }
                });
            }
        })
    }

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    }

    function changeStatus(leadId, item, position) {
        var status = (item.is_active == "1") ? "0" : "1"
        changeLeadStatus({ "id": leadId }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                showMessage(response.message)
                leadsList[position].is_active = status
                var data = renderToStaticMarkup(renderStatusUiText(leadsList[position])).replace("MuiBox-root css-4u9rcm", "MuiBox-root css-k0gowo").replace("MuiBox-root css-1oewkz4", "MuiBox-root css-lv896x")
                // $('#mangeUser').DataTable().row("#userId" + userId).cell("#userStatus" + userId).data(data).draw(false);

                getInReviewsLeadList({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery }).then((response) => {
                    response = JSON.parse(response)
                    
                    if (response.code === 200) {
                        setLeadsList(response.data.Leads)
                        setTotalRecordCount(Math.ceil(response.data.total_record_count / process.env.REACT_APP_RECORD_COUNT))
                    } else {
                        setLeadsList([]);
                    }
                })
            }
        });
    }

    function renderStatusUiText(item) {
        return (
            item.is_active == "1" ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>
        )
    }

    function changeApproveReject(leadId, item, position, data) {
        var LeadApprove = item.is_approve === 0 ? <b><span style={{ "color": "Blue" }}>Pending</span></b> : item.is_approve === 1 ?  <b><span style={{ "color": "Green" }}>Approved</span></b> : <b><span style={{ "color": "Red" }}>Rejected</span></b>;
        approveRejectLead({ "lead_id" : leadId, is_approve : data }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                showMessage(response.message)
                leadsList[position].is_approve = LeadApprove
                var data = renderToStaticMarkup(renderStatusUiText(leadsList[position])).replace("MuiBox-root css-4u9rcm", "MuiBox-root css-k0gowo").replace("MuiBox-root css-1oewkz4", "MuiBox-root css-lv896x")
                // $('#mangeUser').DataTable().row("#userId" + userId).cell("#userStatus" + userId).data(data).draw(false);

                getInReviewsLeadList({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery }).then((response) => {
                    response = JSON.parse(response)
                    
                    if (response.code === 200) {
                        setLeadsList(response.data.Leads)
                        setTotalRecordCount(Math.ceil(response.data.total_record_count / process.env.REACT_APP_RECORD_COUNT))
                    } else {
                        setLeadsList([]);
                    }
                })
            }
        });
    }

    function renderLeadText(item) {
        return (
            item.is_approve === 0 ? <b><span style={{ "color": "Blue" }}>Pending</span></b> : item.is_approve === 1 ?  <b><span style={{ "color": "Green" }}>Approved</span></b> : <b><span style={{ "color": "Red" }}>Rejected</span></b>
        )
    }

    return (
        <>
            <main>
                <div className='content-page mt-3'>
                    <div className='content'>
                        <div className='container-fluid'>
                            <div className='cal'>
                                <div className="col-sm-12">
                                    <div className="page-title-box">
                                        <h4 className="page-title">In-Reviews Leads</h4>
                                    </div>
                                </div>
                                <div className="search-bar">
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchQuery}
                                        onChange={handleSearch}
                                    />
                                </div>
                                {loader === false ?
                                <>
                                    <div className="table-responsive">
                                        <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Industry</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone Number</th>
                                                    <th>Lead Approve</th>
                                                    <th>Status</th>
                                                    <th>Badge</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>

                                            {leadsList.length > 0 ? (
                                            <tbody>
                                                {
                                                    leadsList && leadsList.map((item, index) => {
                                                        const displayIndex = ((pageNo * itemsPerPage) - 10) + index + 1;
                                                        return (
                                                            <tr id={item.id} key={index}>
                                                                <td>
                                                                    {displayIndex}
                                                                    {/* {index+1} */}
                                                                </td>                                                        
                                                                <td>
                                                                    {item.industry == null ? (
                                                                        <p style={{ fontSize: '13px' }}>No data</p>
                                                                        ) : (
                                                                        <>
                                                                            {item.industry}    
                                                                        </>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {item.name == null ? (
                                                                        <p style={{ fontSize: '13px' }}>No data</p>
                                                                        ) : (
                                                                        <>
                                                                            {item.name}    
                                                                        </>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {item.email == null ? (
                                                                        <p style={{ fontSize: '13px' }}>No data</p>
                                                                        ) : (
                                                                        <>
                                                                            {item.email}    
                                                                        </>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {item.phone_number == null ? (
                                                                        <p style={{ fontSize: '13px' }}>No data</p>
                                                                        ) : (
                                                                        <>
                                                                            {item.phone_number}    
                                                                        </>
                                                                    )}
                                                                </td>
                                                                <td id={"userStatus" + item.id}>
                                                                    {renderLeadText(item)}
                                                                </td>
                                                                <td>
                                                                    {item.lead_main_status == "Pending Appt" || item.lead_main_status == "Pending Disp" ? "Update" : item.lead_main_status}
                                                                </td>
                                                                <td >
                                                                    {item.badges}
                                                                </td>
                                                                <td >
                                                                    <Tooltip title="View">
                                                                        <IconButton onClick={() => { viewLeadDetails(item) }}>
                                                                            <FeatherIcon icon="eye" width="18" />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    <Tooltip title="Edit">
                                                                        <IconButton onClick={() => { editLeadDetails(item) }}>
                                                                            <FeatherIcon icon="edit" width="18" color="blue"/>
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    <Tooltip title="Delete">
                                                                        <IconButton onClick={() => { deleteLeadIdWise(item.id) }}>
                                                                            <FeatherIcon icon="trash-2" width="18" color="red" />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            ) : (
                                                leadsList.length === 0 && <h3 className='mt-3'>No Lead data available.</h3>                                )}
                                            
                                        </table>
                                    </div>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=" > "
                                        onPageChange={handlePageClick}
                                        // pageRangeDisplayed={pageCount}
                                        pageCount={totalRecordCount}
                                        previousLabel=" < "
                                        renderOnZeroPageCount={null}
                                        containerClassName="pagination"
                                        pageLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        disabledClassName="disabled"
                                        activeClassName="active"
                                        activeLinkClassName="active-link"
                                    />
                                </>
                                :''}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>

    );
}

export default InReviewList;