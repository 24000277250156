import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { db } from "../../config/firebase";
import Swal from 'sweetalert2';
import moment from 'moment';
import { collection,query,where,orderBy,onSnapshot,getDocs} from "firebase/firestore";
import { useChatCount } from '../../pages/context/total_count';

function ChatUserList() {
    const navigate = useNavigate();
    const { setTotalChatCount } = useChatCount();
    const [count,setCount] = useState(0);
    const [recentChatList,setRecentChatList] = useState("");
    // console.log('recentChatList: ', recentChatList);
    // const [unreadCount,setUnreadCount] = useState([]);
    // console.log('unreadCount: ', unreadCount);

    useEffect(() => {
        getRecentChatList()
    },[])
    
    // useEffect(() => {
    //     const counts = recentChatList && recentChatList.map(user => parseInt(user.chat_count));
    //     setCount(counts);
    // }, [recentChatList]);
    
    // useEffect(() => {
    //     calculateTotalChatCount()
    // },[count])

    // useEffect(() => {
    //     if(recentChatList.length !== unreadCount.length){
    //         Swal.fire({
    //             title: 'Please wait...',
    //             didOpen: () => {
    //                 Swal.showLoading()
    //             }
    //         })
    //     } else {
    //         Swal.close()
    //     }
    // },[recentChatList,unreadCount])

    const getRecentChatList = async () => {
       
        const q = query(collection(db, process.env.REACT_APP_FIREBASE_TABLE_RECENT_CHAT), orderBy("timestamp", "desc"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const fetchedRecentChatList = [];
            // setUnreadCount([])
            querySnapshot.forEach(async (doc) => {
                const data = doc.data();
                data.id = doc.id;
                data.chat_time = formatDateTime(data.timestamp);
                // data.unreadCount = unreadCount;
                fetchedRecentChatList.push(data);
                // const unreadCount = await getUnreadMessageCount(data.recent_chat_id,data.sender_id);
                // setUnreadCount(prevCount => [...prevCount, unreadCount]);
                // fetchedRecentChatList.push({ ...doc.data(), id: doc.id });
            });
            setRecentChatList(fetchedRecentChatList);
        });
        return () => unsubscribe();
    };

    const formatDateTime = (timestamp) => {
        if (!timestamp) return "";
        const date = moment(timestamp.toDate());
        const now = moment();
    
        if (date.isSame(now, 'day')) {
            return `Today at ${date.format('hh:mm A')}`;
        } else if (date.isSame(now.clone().subtract(1, 'day'), 'day')) {
            return `Yesterday at ${date.format('hh:mm A')}`;
        } else {
            return date.format('D MMM YYYY hh:mm A');
        }
    };

    // const getUnreadMessageCount = async (recentChatId,senderId) => {
    //     const recentChatIdCondition = where("recent_chat_id", "==", recentChatId);
    //     const senderIdCondition = where("sender_id", "==", senderId);

    //     const q = query(collection(db, process.env.REACT_APP_FIREBASE_TABLE_CHAT),recentChatIdCondition,senderIdCondition);
    //     let unreadCount = 0;

    //     const querySnapshot = await getDocs(q);

    //     querySnapshot.forEach((doc) => {
    //         const data = doc.data();
    //         if (!data.chat_read) {
    //             unreadCount++;
    //         }
    //     });

    //     return unreadCount;
    // };


    const handleChatHistory = (user_chat_data) => {
        const chatUserData = {
            receiver_username: user_chat_data.sender_id == "A1" ? user_chat_data.receiver_username : user_chat_data.sender_username,
            receiver_id: user_chat_data.sender_id == "A1" ? user_chat_data.receiver_id : user_chat_data.sender_id,
            receiver_firstname: user_chat_data.sender_id == "A1" ? user_chat_data.receiver_firstname : user_chat_data.sender_firstname,
            receiver_image: user_chat_data.sender_id == "A1" ? user_chat_data.receiver_image : user_chat_data.sender_image,
            receiver_lastname: user_chat_data.sender_id == "A1" ? user_chat_data.receiver_lastname : user_chat_data.sender_lastname,
            isActive: true,
        }
        navigate('/chat',{ state : chatUserData })
    }

    const calculateTotalChatCount = async () => {
        try {
            const recentChatCollection = collection(db, process.env.REACT_APP_FIREBASE_TABLE_RECENT_CHAT);
            const recentChatSnapshot = await getDocs(recentChatCollection);

            let totalCount = 0;
            recentChatSnapshot.forEach(doc => {
                const data = doc.data();
                if (data.receiver_id === "A1") {
                    totalCount += parseInt(data.chat_count, 10) || 0;
                }
            });

            setTotalChatCount(totalCount);
        } catch (error) {
            console.log('Error calculating total chat count: ', error);
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: error.message,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        }
    };

    // if(unreadCount.length == 0) return <div className="container"><div className="mt-5"></div></div>

    return (
        <>
        <div className='content-page mt-2'>
            <div className='content'>
                <div className='container-fluid'>
                    <div className='cal'>
                    <div className="row clearfix d-flex justify-content-start" >
                        <div className="chatbody">
                            <div className="chat-main">
                                <h2 className="text-dark">Chat Room</h2>
                                {/* <div className="sideNav1">
                                    <li className="active"><i className="fa-regular fa-comment-dots"></i></li>
                                    <li className="d-none"><i className="fa-solid fa-phone"></i></li>
                                    <li><i className="fa-solid fa-gear"></i></li>
                                    <li><i className="fa-solid fa-trash-can"></i></li>
                                    <li><i className="fa-regular fa-star"></i></li>
                                    <li className="d-none"><i className="fa-solid fa-address-book"></i></li>
                                </div> */}
                                <div className="sideNav2">
                                    <div className="SideNavhead">
                                    </div>
                                    
                                    {/* <div className="SearchInputHolder">
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                        <input className="searchInput" placeholder="Search For Chat.." />

                                        <hr />
                                    </div> */}
                                   
                                    
                                    {recentChatList && recentChatList.map((user,index)=>{
                                        return (
                                            <div className="avatar-group" key={user.id}>
                                                <div className="user-avatar">
                                                    <img src={user.sender_id == "A1" ? user.receiver_image : user.sender_image} alt="userimage" />
                                                </div>

                                                <div className="flex-1" style={{flex:1}} onClick={()=>handleChatHistory(user)}>
                                                    <div className="justify-content-between d-flex text-dark">
                                                    <p className="avatar-name">
                                                        {user.sender_id === "A1" 
                                                            ? `${user.receiver_firstname} ${user.receiver_lastname}` 
                                                            : `${user.sender_firstname} ${user.sender_lastname}`
                                                        }
                                                    </p>
                                                    </div>
                                                    <div className="justify-content-between d-flex text-dark">
                                                        <p className="avatar-msg" style={{textOverflow:"ellipsis"}}>{user.message_type === "image" ? "Photo" :user.message}</p>
                                                        {/* {unreadCount.length === recentChatList.length && unreadCount[index] !== 0 && (
                                                            <span className="d-flex justify-content-center align-items-center bg-dark text-white rounded-circle" style={{ width: '30px', height: '30px' }}>
                                                                {unreadCount[index]}
                                                            </span>
                                                        )} */}
                                                        {user && user.receiver_id === "A1" ?
                                                        (parseInt(user.chat_count)) != 0 && (
                                                            <span className="d-flex justify-content-center align-items-center bg-dark text-white rounded-circle" style={{ width: '30px', height: '30px' }} >
                                                            {parseInt(user.chat_count)}
                                                            </span>
                                                        ) : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {/* <div className="avatar-group">
                                        <div className="user-avatar">
                                            <img src="assets/images/profile.png" alt="userimage" />
                                        </div>

                                        <div className="flex-1" style={{flex:1}} onClick={()=>navigate('/chat')}>
                                            <div className="justify-content-between d-flex text-dark">
                                                <p className="avatar-name">David Johnson</p>
                                                <p>03:45 PM</p>
                                            </div>
                                            <p className="avatar-msg" style={{textOverflow:"ellipsis"}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Earujdsajf
                                                djf
                                                df
                                                dfjdkj
                                                dlkjfl.kjl dlkjf lkjlkdjfm, sequi.asdffasssssssssss Lorem ipsum dolor sit amet consectetur adipisicing elit. Earujdsajf
                                                djf
                                                df
                                                dlkjfl.kjl dlkjf lkjlkdjfm, sequi.asdffasssssssssss Lorem ipsum dolor sit amet consectetur adipisicing elit. Earujdsajf
                                                djf
                                                df
                                            </p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div> 
                    </div>
                    </div>
                </div>         
            </div>
        </div>
        </>
    )
}

export default ChatUserList;
