import React,{ useEffect, useState } from 'react';
import { getQuestionList } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";

import FeatherIcon from "feather-icons-react";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { Modal } from 'react-bootstrap';

function LeadQuestionList() {
    const navigate = useNavigate();
    const [leadQuestionList, setLeadQuestionList] = useState([]);
    const [totalRecordCount, setTotalRecordCount] = useState(0)
    const [pageNo, setPageNo] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [loader, setLoader] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [leadQuestionData,setLeadQuestionData] = useState(null);

    useEffect(() => {

        if(searchQuery === ''){
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading();
                }
            });
        }

        getQuestionList({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery }).then((response) => {
            response = JSON.parse(response)
            // console.log('response: ', response);
            
            if (response.code === 200) {
                Swal.close();
                setLoader(false);
                setLeadQuestionList(response.data.lead_question_list)
                setTotalRecordCount(Math.ceil(response.data.total_record_count / process.env.REACT_APP_RECORD_COUNT))
            } else if (response.code === 404) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setLeadQuestionList([]);
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setLeadQuestionList([]);
            }
        }).catch((error)=>{
            console.log('error: ', error);
            if(error.response.status == 404){
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: 'No data found',
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setLeadQuestionList([]);
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Something went wrong',
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setLeadQuestionList([]);
            }
        })

        // Swal.close();
    }, [searchQuery, pageNo]);


    const handlePageClick = (event) => {
        setPageNo(event.selected + 1)
    };

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    }

    const handleOpenModal = () => {
        setShowModal(true);
    };
    
    const handleCloseModal = async () => {
        await setLeadQuestionData(null)
        setShowModal(false);
    };
    
    const viewLeadQuestionDetails = async (lead_question_data,index_value) => {
        lead_question_data.index_value = index_value;
        await setLeadQuestionData(lead_question_data)
        handleOpenModal()
    }

    return (
        <>
            <main>
                <div className='content-page mt-3'>
                    <div className='content'>
                        <div className='container-fluid'>
                            <div className='cal'>
                                <div className="col-sm-12">
                                    <div className="page-title-box">
                                        <h4 className="page-title">Lead Question List</h4>
                                    </div>
                                </div>
                                <div className="search-bar">
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchQuery}
                                        onChange={handleSearch}
                                    />
                                </div>
                                {loader === false ?
                                <>
                                    <div className="table-responsive">
                                        <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Setter Name</th>
                                                    <th>Closer Name</th>
                                                    <th>Lead Title</th>
                                                    <th>Date Time</th>
                                                    <th>Question</th>
                                                    {/* <th>Status</th>
                                                    <th>Status Action</th>  */}
                                                    <th>Action</th>
                                                </tr>
                                            </thead>

                                            {leadQuestionList.length > 0 ? (
                                            <tbody>
                                                {
                                                    leadQuestionList && leadQuestionList.map((item, index) => {
                                                        return (
                                                            <tr id={item.id} key={index}>
                                                                <td >
                                                                    {index+1}
                                                                </td>
                                                                {/* <td>
                                                                    <td>{item.industry && item.industry.length > 0 ? item.industry.map(ind => ind.industry_name).join(', ') : 'N/A'}</td>
                                                                </td> */}
                                                                <td>
                                                                    {item.setter_data.firstname+" "+item.setter_data.lastname}
                                                                    {/* {Array.isArray(item.industry) ? item.industry.map(ind => ind.industry_name).join(', ') : 'N/A'} */}
                                                                </td>
                                                                <td >
                                                                    {item.closer_data.firstname+" "+item.closer_data.lastname}
                                                                </td>
                                                                <td>
                                                                    {item.lead_data.name}
                                                                </td>
                                                                <td>
                                                                    {moment.utc(item.createdAt).local().format('YYYY-MM-DD hh:mm A')}
                                                                </td>
                                                                <td className="text-truncate" style={{ maxWidth: '200px' }}>
                                                                    {item.question}
                                                                </td>
                                                                {/* <td>
                                                                    {item.is_approve === 0 ? <b><span style={{ "color": "Blue" }}>Pending</span></b> : item.is_approve === 1 ?  <b><span style={{ "color": "Green" }}>Approve</span></b> : <b><span style={{ "color": "Red" }}>Reject</span></b>}
                                                                </td> */}

                                                                {/* <td id={"userStatus" + item.id}>
                                                                    {renderStatusUiText(item)}
                                                                </td>
                                                                <td id={"userStatus" + item.id}>
                                                                    <Tooltip title="Change Status">
                                                                        <IconButton onClick={() => { changeStatus(item.id, item, index) }}>
                                                                            <FeatherIcon icon="repeat" width="18" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </td> */}
                                                                <td >
                                                                    <Tooltip title="View">
                                                                        <IconButton onClick={() => { viewLeadQuestionDetails(item,index) }}>
                                                                            <FeatherIcon icon="eye" width="18" />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                {/* <Tooltip title="Edit">
                                                                        <IconButton onClick={() => { editLeadDetails(item) }}>
                                                                            <FeatherIcon icon="edit" width="18" color="blue"/>
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    <Tooltip title="Delete">
                                                                        <IconButton onClick={() => { deleteLeadIdWise(item.id) }}>
                                                                            <FeatherIcon icon="trash-2" width="18" color="red" />
                                                                        </IconButton>
                                                                    </Tooltip> */}

                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            ) : (
                                                leadQuestionList.length === 0 && <h3 className='mt-3'>No Lead question data available.</h3>                                )}
                                            
                                        </table>
                                    </div>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=" > "
                                        onPageChange={handlePageClick}
                                        // pageRangeDisplayed={pageCount}
                                        pageCount={totalRecordCount}
                                        previousLabel=" < "
                                        renderOnZeroPageCount={null}
                                        containerClassName="pagination"
                                        pageLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        disabledClassName="disabled"
                                        activeClassName="active"
                                        activeLinkClassName="active-link"
                                    />
                                </>
                                :''}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header>
                    <Modal.Title>Lead Question Details</Modal.Title>
                    <Tooltip title="Close">
                        <IconButton onClick={handleCloseModal}>
                            <FeatherIcon icon="x-square" width="35" />
                        </IconButton>
                    </Tooltip>
                </Modal.Header>
                <Modal.Body>
                <div className="mb-3">
                    <label htmlFor="question" className="font-weight-bold h6">
                        ID
                    </label>
                    <p id="question" className="form-label mt-2">
                        {leadQuestionData && leadQuestionData.index_value + 1}
                    </p>
                </div>
                <div className="mb-3">
                    <label htmlFor="question" className="font-weight-bold h6">
                        Setter Name
                    </label>
                    <p id="question" className="form-label mt-2">
                        {leadQuestionData && leadQuestionData.setter_data.firstname+" "+leadQuestionData.setter_data.lastname}
                    </p>
                </div>
                <div className="mb-3">
                    <label htmlFor="question" className="font-weight-bold h6">
                        Closer Name
                    </label>
                    <p id="question" className="form-label mt-2">
                        {leadQuestionData && leadQuestionData.closer_data.firstname+" "+leadQuestionData.closer_data.lastname}
                    </p>
                </div>
                <div className="mb-3">
                    <label htmlFor="question" className="font-weight-bold h6">
                        Lead Title
                    </label>
                    <p id="question" className="form-label mt-2">
                        {leadQuestionData && leadQuestionData.lead_data.name}
                    </p>
                </div>
                <div className="mb-3">
                    <label htmlFor="question" className="font-weight-bold h6">
                        Date Time
                    </label>
                    <p id="question" className="form-label mt-2">
                        {moment.utc(leadQuestionData && leadQuestionData.createdAt).local().format('YYYY-MM-DD hh:mm A')}
                    </p>
                </div>
                <div className="mb-3">
                    <label htmlFor="question" className="font-weight-bold h6">
                        Question
                    </label>
                    <p id="question" className="form-label mt-2">
                        {leadQuestionData && leadQuestionData.question}
                    </p>
                </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default LeadQuestionList;
