import React,{ useEffect, useState } from 'react';
import { getCloserSetterTutorialList, getCategoryList, addEditTutorial, activeInactiveTutorial, deleteTutorial } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";

import FeatherIcon from "feather-icons-react";
// import Tooltip from '@material-ui/core/Tooltip';
import Tooltip from '@mui/material/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
import IconButton from '@mui/material/IconButton';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AWS from 'aws-sdk';

function ManageSetterTutorials() {
    const navigate = useNavigate();
    const [setterTutorialList, setSetterTutorialList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [totalRecordCount, setTotalRecordCount] = useState(0)
    const [pageNo, setPageNo] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [loader, setLoader] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [uploadVideo, setUploadVideo] = useState(null);
    const [tutorialData,setTutorialData] = useState(null);


    useEffect(() => {

        if(searchQuery === ''){
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading();
                }
            });
        }

        getCategoryListFuncation()
        getCloserSetterTutorialListFuncation()

        // Swal.close();
    }, [searchQuery, pageNo]);

    const getCategoryListFuncation = () => {
        getCategoryList({ 'search_text': searchQuery }).then((response) => {
            response = JSON.parse(response)
            // console.log('response: ', response);
            
            if (response.code === 200) {
                // console.log(response)
                // Swal.close();
                // setLoader(false);
                setCategoryList(response.data)
                // setTotalRecordCount(Math.ceil(response.data.total_record_count / process.env.REACT_APP_RECORD_COUNT))
            } else if (response.code === 404) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setCategoryList([]);
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setCategoryList([]);
            }
        }).catch((error)=>{
            console.log('error: ', error);
            if(error.response.status == 404){
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: 'No data found',
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setCategoryList([]);
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Something went wrong',
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setCategoryList([]);
            }
        })
    }

    const getCloserSetterTutorialListFuncation = () => {
        getCloserSetterTutorialList({ "role": "setter", "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT), 'search_text': searchQuery }).then((response) => {
            response = JSON.parse(response)
            // console.log('response: ', response);
            
            if (response.code === 200) {
                // console.log(response)
                Swal.close();
                setLoader(false);
                setSetterTutorialList(response.data.tutorial_list)
                setTotalRecordCount(Math.ceil(response.data.total_record_count / process.env.REACT_APP_RECORD_COUNT))
            } else if (response.code === 404) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setSetterTutorialList([])
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setSetterTutorialList([]);
            }
        }).catch((error)=>{
            console.log('error: ', error);
            if(error.response.status == 404){
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: 'No data found',
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setSetterTutorialList([]);
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Something went wrong',
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setSetterTutorialList([]);
            }
        })
    }

    const handlePageClick = (event) => {
        setPageNo(event.selected + 1)
    };
    
    const editSetterTutorial = (tutorial_data) => {
        setTutorialData(tutorial_data)
        setValue2('name',tutorial_data.name,{shouldValidate : false})
        setValue2('description',tutorial_data.description,{shouldValidate : false})
        setValue2('video_file',tutorial_data.file_name,{shouldValidate : false})
        setValue2('tutorial_url',tutorial_data.file_name_url,{shouldValidate : false})
        if(tutorial_data.tutorial_category != null){
            setValue2('category',tutorial_data.tutorial_category.id,{shouldValidate : false})
        }
        handleOpenEditModal()
    }

    const deleteSetterTutorial = (tutorial_data) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            text: "You won't be able to revert this!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#02C9D6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Please wait...',
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
        
                deleteParticularSetterTutorial(tutorial_data)
            }
        })

        
    }

    const deleteParticularSetterTutorial = (tutorial_data) => {
        const tutorialObj = {
            tutorial_id : tutorial_data.id
        };

        deleteTutorial(tutorialObj).then((response) => {
            response = JSON.parse(response)
            // console.log('response: ', response);
            
            if (response.code === 200) {
                // console.log(response)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setTimeout(()=>{
                    getCloserSetterTutorialListFuncation()
                },500)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
            }
        })
    }

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenEditModal = () => {
        setShowEditModal(true);
    };

    const handleCloseEditModal = () => {
        setTutorialData(null);
        setShowEditModal(false);
        reset2()
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required("Please enter name"),
            // .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field')
            // .max(128, "Name must be at most 128 character"),
        description: Yup.string()
            .required("Please enter description"),
            // .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field'),
        video_file: Yup.mixed()
            .test(
                'fileRequired',
                'Please select video file',(value) => {
                    if (value && value.length > 0) {
                        return true;
                    }
                    return false;
                }
            )
            .test('fileType', 'Only video files are allowed', (value) => {
                if (!value || !value[0]) return true;
                return value[0].type.startsWith('video/');
            }),
        category: Yup.string()
            .required("Please select category")
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState,reset } = useForm(formOptions);
    const { errors } = formState;

    // upload video
    const uploadVideoData = async (file,file_path) => {
        const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
        const REGION = process.env.REACT_APP_REGION;       

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            region: REGION,
          });
          const ext = file.name.split('.').pop()
          const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
          });

        try {
            const newFileName = new Date().getTime() + '.'+ext;
            const params = {
                Bucket: S3_BUCKET + file_path,
                Key: newFileName,
                Body: file,
                ACL: "public-read",
            };

            return new Promise((resolve, reject) => {
                s3.putObject(params, (err, data) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(newFileName);
                    }
                });
            });
        } catch (error) {
            console.error("Error in UploadVideoData:", error);
        }
    };

    const onSubmit1 = async (data) => {
        if (uploadVideo != null) {
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            await uploadVideoData(uploadVideo,"/tutorial").then((videodata)=>{
                Swal.close()
                // console.log('videorecord: ', videodata);
                data.video_file = videodata;
                setUploadVideo(null)

            }).catch((e)=>{
                Swal.close()
            });
        }
        // console.log('data: ', data);
        addEditTutorialFunction("add",data)
        reset()
        handleCloseModal()
    }

    const addEditTutorialFunction = async (type,data) => {
    
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading();
            }
        });

        let tutorialDataObj;
        if(type === "add"){
            tutorialDataObj = {
                type : "add",
                role: "setter",
                category_id: data.category,
                name : data.name,
                description: data.description,
                file_name: data.video_file,
            }   
        } 
        
        if(type === "edit"){
            tutorialDataObj = {
                type : "edit",
                role: "setter",
                tutorial_id: tutorialData.id,
                category_id: data.category,
                name : data.name,
                description: data.description,
                file_name: data.video_file
            }
        }

        // console.log('tutorialDataObj: ', tutorialDataObj);
        addEditTutorial(tutorialDataObj).then((response) => {
            response = JSON.parse(response)
            // console.log('response: ', response);
            
            if (response.code === 200) {
                // console.log(response)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setTimeout(()=>{
                    getCloserSetterTutorialListFuncation()
                },500)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
            }
        })
    }

    const changeTutorialStatus = (tutorial_data) => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading();
            }
        });

        const tutorialObj = {
            tutorial_id : tutorial_data.id
        };

        activeInactiveTutorial(tutorialObj).then((response) => {
            response = JSON.parse(response)
            // console.log('response: ', response);
            
            if (response.code === 200) {
                // console.log(response)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
                setTimeout(()=>{
                    getCloserSetterTutorialListFuncation()
                },500)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false);
            }
        })
    }

    const renderStatusUiText = (item) => {
        return (
            item.is_active == 1 ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>
        )
    }

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    };

    const validationSchema2 = Yup.object().shape({
        name: Yup.string()
            .required("Please enter name"),
            // .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field')
            // .max(128, "Name must be at most 128 character"),
        description: Yup.string()
            .required("Please enter description"),
            // .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field'),
        // video_file: Yup.mixed()
        //     .test(
        //         'fileRequired',
        //         'Please select video file',(value) => {
        //             if (value && value.length > 0) {
        //                 return true;
        //             }
        //             return false;
        //         }
        //     )
        //     .test('fileType', 'Only video files are allowed', (value) => {
        //         if (!value || !value[0]) return true;
        //         return value[0].type.startsWith('video/');
        //     }),
        category: Yup.string()
            .required("Please select category")
    });

    const formOptions2 = { resolver: yupResolver(validationSchema2) };
    const { register : register2, setValue : setValue2, watch : watch2, handleSubmit : handleSubmit2 , formState : formState2, reset : reset2 } = useForm(formOptions2);
    const { errors : errors2 } = formState2;

    const onSubmit2 = async (data) => {
        // console.log('data: ', data);
        if (uploadVideo != null) {
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            await uploadVideoData(uploadVideo,"/tutorial").then((videodata)=>{
                Swal.close()
                // console.log('videorecord: ', videodata);
                data.video_file = videodata;
                setUploadVideo(null)
            }).catch((e)=>{
                Swal.close()
            });
        }
        // console.log('data: ', data);
        addEditTutorialFunction("edit",data)
        setTutorialData(null)
        handleCloseEditModal()
    }

    return (
        <>
            <main>
                <div className='content-page mt-3'>
                    <div className='content'>
                        <div className='container-fluid'>
                            <div className='cal'>
                                <div className="col-sm-12">
                                    <div className="page-title-box d-flex justify-content-between">
                                        <h4 className="page-title">Setter Tutorials</h4>
                                        <i className="fa-solid fa-circle-plus plus-icon" onClick={handleOpenModal}></i>
                                    </div>
                                </div>
                                <div className="search-bar">
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchQuery}
                                        onChange={handleSearch}
                                    />
                                </div>
                                {loader === false ?
                                <>
                                    <div className="table-responsive">
                                        <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Name</th>
                                                    <th>Category</th>
                                                    <th>Status</th>
                                                    <th>Status Action</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>        
                                            {setterTutorialList.length > 0 ? (
                                            <tbody>
                                                {
                                                    setterTutorialList && setterTutorialList.map((item, index) => {
                                                        return (
                                                            <tr id={item.id} key={index}>
                                                                <td >
                                                                    {index+1}
                                                                </td>
                                                                <td >
                                                                    {item.name}
                                                                </td>
                                                                <td >
                                                                    {item.tutorial_category && item.tutorial_category.name}
                                                                </td>
                                                                <td id={"userStatus" + item.id}>
                                                                    {renderStatusUiText(item)}
                                                                </td>
                                                                <td id={"userStatus" + item.id}>
                                                                    <Tooltip title="Change Status">
                                                                        <IconButton onClick={() => { changeTutorialStatus(item) }}>
                                                                            <FeatherIcon icon="repeat" width="18" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </td>
                                                                <td >
                                                                    <Tooltip title="Edit">
                                                                        <IconButton onClick={() => { editSetterTutorial(item) }}>
                                                                            <FeatherIcon icon="edit" width="18" color="blue" />
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    <Tooltip title="Delete">
                                                                        <IconButton onClick={() => { deleteSetterTutorial(item) }}>
                                                                            <FeatherIcon icon="trash-2" width="18" color="red" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            ) : (
                                                setterTutorialList.length == 0 && <h3 className='mt-3'>No setter tutorial data available.</h3>                                )}
                                            
                                        </table>
                                    </div>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=" > "
                                        onPageChange={handlePageClick}
                                        // pageRangeDisplayed={pageCount}
                                        pageCount={totalRecordCount}
                                        previousLabel=" < "
                                        renderOnZeroPageCount={null}
                                        containerClassName="pagination"
                                        pageLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        disabledClassName="disabled"
                                        activeClassName="active"
                                        activeLinkClassName="active-link"
                                    />
                                </>
                                :''}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header>
                    <Modal.Title>Add Tutorial</Modal.Title>
                    <Tooltip title="Close">
                        <IconButton onClick={handleCloseModal}>
                            <FeatherIcon icon="x-square" width="35" />
                        </IconButton>
                    </Tooltip>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit1)}>
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Name
                        </label>
                        <input
                        type="text"
                        {...register('name')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter name"
                        />
                        <div className="invalid-feedback">{errors.name?.message}</div>
                    </div>
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Description
                        </label>
                        <input
                        type="text"
                        {...register('description')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter description"
                        />
                        <div className="invalid-feedback">{errors.description?.message}</div>
                    </div>
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        File
                        </label>
                        <input
                        type="file"
                        accept="video/*"
                        {...register('video_file')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter e.g., xyz"
                        onChange={(e)=>setUploadVideo(e.target.files[0])}
                        />
                        <div className="invalid-feedback">{errors.video_file?.message}</div>
                    </div>
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Select Category
                        </label>
                        {/* <input
                        type="text"
                        {...register('category')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter category"
                        /> */}
                        {categoryList.length > 0 ? 
                            <select className="form-control" {...register('category')}>
                                <option value="">Select Category</option>
                                {categoryList && categoryList.map((item) => (
                                    <option key={item.name} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select> : ""}
                        <div className="invalid-feedback">{errors.category?.message}</div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button
                            type="submit"
                            id="buttonclose"
                            className="btn btn-primary"
                        >
                            Submit
                        </button>
                    </div>    
                        
                </form>
                </Modal.Body>
            </Modal>
            <Modal show={showEditModal} onHide={handleCloseEditModal} centered>
                <Modal.Header>
                    <Modal.Title>Edit Tutorial</Modal.Title>
                    <Tooltip title="Close">
                        <IconButton onClick={handleCloseEditModal}>
                            <FeatherIcon icon="x-square" width="35" />
                        </IconButton>
                    </Tooltip>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={handleSubmit2(onSubmit2)}>
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Name
                        </label>
                        <input
                        type="text"
                        {...register2('name')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter name"
                        />
                        <div className="invalid-feedback">{errors2.name?.message}</div>
                    </div>
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Description
                        </label>
                        <input
                        type="text"
                        {...register2('description')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter description"
                        />
                        <div className="invalid-feedback">{errors2.description?.message}</div>
                    </div>
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        File
                        </label>
                        <input
                        type="file"
                        accept="video/*"
                        {...register2('video_file')}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter e.g., xyz"
                        onChange={(e)=>setUploadVideo(e.target.files[0])}
                        />
                        <div className="invalid-feedback">{errors2.video_file?.message}</div>
                    </div>
                    <div className="mb-3">
                        <a href={watch2('tutorial_url')} {...register2('tutorial_url')} target="_blank" className="text-primary" >View Video</a>
                    </div>
                    <div className="mb-3">
                        <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        >
                        Select Category
                        </label>
                        {categoryList.length > 0 ? 
                            <select className="form-control" {...register2('category')}>
                                <option value="">Select Category</option>
                                {categoryList && categoryList.map((item) => (
                                    <option key={item.name} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select> : ""}
                        <div className="invalid-feedback">{errors2.category?.message}</div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button
                            type="submit"
                            id="buttonclose"
                            className="btn btn-primary"
                        >
                            Update
                        </button>
                    </div>    
                        
                </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ManageSetterTutorials;

