import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import { changePassword } from '../../api/apiHandler';
import Swal from 'sweetalert2';
import { auth } from "../../config/firebase";
import { updatePassword } from 'firebase/auth';

function ChangePassword() {
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string()
            .required('Current Password is required')
            .min(6, 'Current Password must be at least 6 characters'),
        newPassword: Yup.string()
            .required('New Password is required')
            .min(6, 'New Password must be at least 6 characters'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    const onSubmit = (data) => {
        // console.log(data)
        changePassword({ "old_password": data.oldPassword, "new_password": data.newPassword }).then((response) => {
            response = JSON.parse(response)

            if (response.code === 200) {
                handleChangePassword(process.env.REACT_APP_FIREBASE_STATIC_PASSWORD,response)
                // Swal.fire({
                //     position: 'top-end',
                //     icon: 'success',
                //     title: response.message,
                //     toast: true,
                //     showConfirmButton: false,
                //     timer: 2000
                // })
                // setTimeout(() => {
                //     navigate("/")
                // }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    const handleChangePassword = async (newPassword,response) => {
        const user = auth.currentUser;
        console.log('user: ', user);
        if (user) {
            try {
                await updatePassword(user, newPassword);
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    navigate("/")
                }, 2000)
            } catch (error) {
                console.error('Error updating password: ', error);
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: error.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        } else {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'No user is currently signed in',
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        }
    };

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-4 col-md-4 col-sm-4'>
                        <h5>Change Password</h5>
                        <hr></hr>
                        <form className="form-horizontal m-t-30" onSubmit={handleSubmit(onSubmit)}>

                            <div className="form-group">
                                <label htmlFor="useremail">Current Password</label>
                                <input type="password" {...register('oldPassword')} className="form-control" placeholder="Enter current password" />
                                <div className="invalid-feedback">{errors.oldPassword?.message}</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="useremail">New Password</label>
                                <input type="password"  {...register('newPassword')} className="form-control" placeholder="Enter new password" />
                                <div className="invalid-feedback">{errors.newPassword?.message}</div>
                            </div>

                            <div className="form-group">
                                <div style={{ textAlign: "center" }}>
                                    <button className="btn btn-primary w-md waves-effect waves-light mt-3" style={{ backgroundColor: "#043a69" }} type="submit">Change Password</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangePassword;