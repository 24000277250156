import React, { useEffect, useState } from 'react'
import { getFaqListing, changeFaqStatus, deleteFaq } from '../../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
// import Tooltip from '@material-ui/core/Tooltip';
import Tooltip from '@mui/material/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
import IconButton from '@mui/material/IconButton';
// import Switch from '@material-ui/core/Switch';
import Switch from '@mui/material/Switch';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

function FaqList() {
    const navigate = useNavigate();

    const [faqList, setFaqList] = useState(null)
    const [totalQuestion, setTotalQuestion] = useState(0)
    const [pageNo, setPageNo] = useState(1);

    // const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        getFaqListing({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT)}).then((response) => {
            Swal.close()
            response = JSON.parse(response)
            if (response.code == 200) {
                setFaqList(response.data.Faq)
                setTotalQuestion(Math.ceil(response.data.total_record_count / process.env.REACT_APP_RECORD_COUNT))
            } else {
                setFaqList([])
            }
        })
    }, [])

    useEffect(() => {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                pageLength: 8,
                processing: true,
                order: [[0, 'desc']],
                dom: 'Bfrtip',
                buttons: ['copy', 'csv', 'print'],
                "bDestroy": true,
            }
        );
    }, [faqList])

    const showMessage = (msg) => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: msg,
            toast: true,
            showConfirmButton: false,
            timer: 1500
        })
    }

    function changeStatus(faqId, item, position) {

        var status = (item.is_active == "1") ? "0" : "1"

        changeFaqStatus({ "id": faqId }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                showMessage(response.message)
                faqList[position].is_active = status
                var data = renderToStaticMarkup(renderStatusUiText(faqList[position])).replace("MuiBox-root css-4u9rcm", "MuiBox-root css-k0gowo").replace("MuiBox-root css-1oewkz4", "MuiBox-root css-lv896x")
                $('#mangeUser').DataTable().row("#faqId" + faqId).cell("#faqStatus" + faqId).data(data).draw(false);
            }
        });
    }

    function renderStatusUiText(item) {
        return (
            item.is_active == "1" ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>
        )
    }

    function deleteFaqIdWise(faqId) {
        // console.log("Deleting FAQ with ID:", faqId); 
        Swal.fire({
            title: 'Are you sure you want to delete?',
            text: "You won't be able to revert this!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#02C9D6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                // console.log("faqId " + faqId)
                deleteFaq({ "faq_id": faqId }).then((response) => {
                    response = JSON.parse(response)
                    if (response.code == 200) {
                        // console.log("Deleting row with ID----:", faqId);
                        $('#mangeUser').DataTable().row("#faqId" + faqId).remove().draw(false);
                        showMessage(response.message)
                        // setFaqList(prevFaqList => prevFaqList.filter(faq => faq.id !== faqId));
                        
                        getFaqListing({ "page": pageNo, "record_count": parseInt(process.env.REACT_APP_RECORD_COUNT)}).then((response) => {
                            $('#mangeUser').DataTable().destroy();
                            response = JSON.parse(response)
                            if (response.code == 200) {
                                setFaqList(response.data.Faq)
                                setTotalQuestion(Math.ceil(response.data.total_record_count / process.env.REACT_APP_RECORD_COUNT))
                            } else {
                                setFaqList([])
                            }
                        })
                    }
                });
            }
        })
    }

    const editFaq = (item) => {
        navigate(`/faqList/editfaq/${item.id}`);
    }
    const addFaq = () => {
        navigate(`/faqList/addfaq`);
    }

    const handlePageClick = (event) => {
        setPageNo(event.selected + 1)
    };

    if (faqList == null) return <> </>

    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>
                            <button className='add' onClick={addFaq}><span className="mdi mdi-plus"></span></button>
                            <div className="table-responsive">
                                <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: "center" }}>ID</th>
                                            <th style={{ textAlign: "center" }}>Question</th>
                                            <th style={{ textAlign: "center" }}>Answer</th>
                                            <th style={{ textAlign: "center" }}>Status</th>
                                            <th style={{ textAlign: "center" }}>Status Action</th>
                                            <th style={{ textAlign: "center" }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th style={{ textAlign: "center" }}>ID</th>
                                            <th style={{ textAlign: "center" }}>Question</th>
                                            <th style={{ textAlign: "center" }}>Answer</th>
                                            <th style={{ textAlign: "center" }}>Status</th>
                                            <th style={{ textAlign: "center" }}>Status Action</th>
                                            <th style={{ textAlign: "center" }}>Action</th>
                                        </tr>
                                    </tfoot>
                                    {faqList.length > 0 ? (
                                    <tbody>
                                        {
                                            faqList && faqList.map((item, index) => {
                                                return (
                                                    <tr id={"faqId" + index} >
                                                        <td style={{ textAlign: "center" }}>
                                                            {item.id}
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>
                                                            {item.question}
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>
                                                            {item.answer}
                                                        </td>
                                                        <td id={"faqStatus" + item.id} style={{ textAlign: "center" }}>
                                                            {renderStatusUiText(item)}
                                                        </td>
                                                        <td id={"faqStatus" + item.id} style={{ textAlign: "center" }}>
                                                            <Tooltip title="Change Status">
                                                                <IconButton onClick={() => { changeStatus(item.id, item, index) }}>
                                                                    <FeatherIcon icon="repeat" width="18" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>

                                                            <Tooltip title="Edit">
                                                                <IconButton onClick={() => { editFaq(item) }}>
                                                                    <FeatherIcon style={{ color: 'blue' }} icon="edit" width="18" />
                                                                </IconButton>
                                                            </Tooltip>

                                                            <Tooltip title="Delete">
                                                                <IconButton onClick={()=>{deleteFaqIdWise(item.id)}}>                                         
                                                                    <FeatherIcon style={{ color: 'red' }} icon="trash-2" width="18" />
                                                                </IconButton>
                                                            </Tooltip>

                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                    ) : (
                                        faqList.length === 0 && <h3 className='mt-3'>No Faq's data available.</h3>                                
                                    )}
                                </table>
                            </div>
                            {/* <ReactPaginate
                                breakLabel="..."
                                nextLabel=" > "
                                onPageChange={handlePageClick}
                                // pageRangeDisplayed={pageCount}
                                pageCount={totalQuestion}
                                previousLabel=" < "
                                renderOnZeroPageCount={null}
                                containerClassName="pagination"
                                pageLinkClassName="page-link"
                                previousLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                disabledClassName="disabled"
                                activeClassName="active"
                                activeLinkClassName="active-link"
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FaqList;