import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { addUserAgreement, getManagePage } from '../../api/apiHandler';
import Swal from 'sweetalert2'
import AWS from 'aws-sdk';

const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
const REGION = process.env.REACT_APP_REGION;       

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: REGION,
});
const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
});

function UserAgreement() {

    const [userAgreement, setUserAgreement] = useState(null);
    const [uploadPdf, setUploadPdf] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading();
            }
        });
        getManagePage({"tag": "USER_AGREEMENT"}).then((response) => {
            response = JSON.parse(response)
            Swal.close();
            if (response.code === 200) {
                setUserAgreement(response.data);
            }
        });
    }, []);

    function redirect() {
        sessionStorage.setItem("flag",0)    
        navigate("/")
    }

    const validationSchema = Yup.object().shape({
        user_agreement: Yup.mixed()
            .test(
                'fileRequired',
                'Please select an pdf file',
                (value) => {
                    if (value && value.length > 0) {
                        return true;
                    }
                    return false;
                }
            )
            .test('fileType', 'Only pdf files are allowed', (value) => {
                if (!value || !value[0]) return true;
                return value[0].type.startsWith('application/pdf');
            }),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, reset } = useForm(formOptions);
    const { errors } = formState;

    // upload pdf
    const uploadPdfData = async (file,file_path) => {
        
        try {
            // const ext = file.name.split('.').pop()
            const newFileName = "default-user-agreement.pdf";
            const params = {
                Bucket: S3_BUCKET + file_path,
                Key: newFileName,
                Body: file,
                ACL: "public-read",
            };

            return new Promise((resolve, reject) => {
                s3.putObject(params, (err, data) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(newFileName);
                    }
                });
            });
        } catch (error) {
            console.error("Error in UploadPdfData:", error);
        }
    };

    // delete pdf
    // const deletePdfData = async (file,file_path) => {
    //     try {
    //         const params = {
    //             Bucket: S3_BUCKET + file_path,
    //             Key: file,
    //         };
    //         return new Promise((resolve, reject) => {
    //             s3.deleteObject(params, (err, data) => {
    //                 if (err) {
    //                     console.error("Error deleting file from S3:", err);
    //                     reject(err);
    //                 } else {
    //                     console.log("File deleted successfully", data);
    //                     resolve(data);
    //                 }
    //             });
    //         });
    //     } catch (error) {
    //         console.error("Error in deletePdfData:", error);
    //     }
    // };

    const onSubmit = async (data) => {
        if (uploadPdf != null) {
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            // await deletePdfData("default-user-agreement.pdf","/user-agreement").then((imagedata)=>{

            // }).catch((e)=>{
            //     Swal.close()
            // });
            await uploadPdfData(uploadPdf,"/user-agreement").then((imagedata)=>{
                Swal.close()
                // console.log('imagedata: ', imagedata);
                data.user_agreement = imagedata
                setUploadPdf(null)

            }).catch((e)=>{
                Swal.close()
            });

            addUserAgreement({files : data.user_agreement}).then((response) => {
                response = JSON.parse(response);
                if (response.code == 200) {
                  Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  setTimeout(() => {
                    navigate("/");
                  }, 2000);
                } else {
                  Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                }
            });
        }
        
    }

    if (userAgreement == null) return <> </>

    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid' >
                        <div className='cal col-xs-11 col-md-11 col-sm-11'>
                            <div className='about mb-3'>
                                <h3>User Agreement</h3>
                            </div>
                            {/* <ReactQuill value={text} style={{ height: 300 }} onChange={(value) => { setText(value) }} placeholder="Type here..." /> */}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">
                                        File
                                    </label>
                                    <input type="file" accept="pdf/*" {...register('user_agreement')} className="form-control" id="exampleFormControlInput1" onChange={(e)=>setUploadPdf(e.target.files[0])}
                                    />
                                    <div className="invalid-feedback">{errors.user_agreement?.message}</div>
                                </div>
                                <div className="mb-3">
                                    <a href={userAgreement.files} target="_blank" className="text-primary" >View PDF</a>
                                </div>
                                <button type="submit" className="btn btn-primary waves-effect waves-light m-2"> Upload </button>
                                <button className="btn btn-danger" onClick={redirect} > Cancel </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserAgreement;