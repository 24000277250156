import React, { useEffect, useState } from 'react';
import { getManagePage, getStaticPageData } from '../../api/apiHandler';
import parse from 'html-react-parser';
import Swal from 'sweetalert2'


function AboutUsPage() {
    
    const [text, setText] = useState(null);

    useEffect(() => {
        
        // Swal.fire({
        //     title: 'Please wait...',
        //     didOpen: () => {
        //         Swal.showLoading()
        //     }
        // })
        getStaticPageData({"tag":"ABOUT_US"}).then((response)=>{
            Swal.close()
            response = JSON.parse(response)
            if(response.code == 200){ 
                // console.log(response.data,"about data")
                setText(response.data[0].content);
            }
        });
    }, [])

    if (text == null) return <> </>

    return (
        <>
        <div className="wrapper-page">
            {/* <h1 className='text-center'>About Us</h1> */}
            <div className="card ">
                    <div className="card-body">
                        {parse(text)}
                    </div>
            </div>
        </div>
        </>
    );
}


export default AboutUsPage;