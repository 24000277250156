import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { login } from '../../api/apiHandler';
import { v4 as uuid } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loginStoreData } from '../common/RedirectPathMange';
import Swal from 'sweetalert2'
import { auth,db } from "../../config/firebase";
import { createUserWithEmailAndPassword,signInWithEmailAndPassword,fetchSignInMethodsForEmail } from "firebase/auth";
import { getDoc,setDoc,doc} from "firebase/firestore";

function Login() {
    
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        password: Yup.string()
            .required('Password is required')
        // .min(6, 'Password must be at least 6 characters')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = (data) => {
        var newData = {
            "email": data.email,
            "password": data.password
        }

        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading();
            }
        });

        // console.log("Data",newData)
        login(newData).then((res) => {
            var response = JSON.parse(res)

            if (response.code === 200) {
                // loginStoreData(response.data)
                // checkIfUserExists(data.email,data.password,response)
                // adminFirebaseLogin(data.email,data.password,response)
                adminFirebaseSignUp(data.email,process.env.REACT_APP_FIREBASE_STATIC_PASSWORD,response)
                // navigate("/");
                // console.log(response.data,"data.....")
            }
            else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    const adminFirebaseLogin = async (email,password,response) => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            await setAdminData();
            // console.log("login successs");
            loginStoreData(response.data)
            Swal.close();
        } catch (err) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'INVALID_LOGIN_CREDENTIALS',
                toast: true,
                showConfirmButton: false,
                timer: 2000
            })
        }
    }

    const adminFirebaseSignUp = async (email,password,response) => {
        try {
            await createUserWithEmailAndPassword(auth, email, password);
            adminFirebaseLogin(email,password,response)
            // alert('User created successfully');
        } catch (err) {
            // console.log('err: ', err);
            if (err.code === 'auth/email-already-in-use') {
                // console.log("email already register");
                adminFirebaseLogin(email,password,response)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Something went wrong',
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        }        
    }

    const setAdminData = async () => {
        try {
            const userId = "A1"; // Static document ID
    
            // Create a reference to the document with the static ID
            const userDocRef = doc(db, process.env.REACT_APP_FIREBASE_TABLE_USER, userId);
    
            // Attempt to get the document to check if it exists
            const docSnapshot = await getDoc(userDocRef);
    
            const userDocData = {
                first_name: "Admin",
                last_name: "Admin",
                user_name: "Admin",
                user_image: "",
                user_id: userId,
                is_online: true,
                device_type: "W",
                device_token: ""
            };
    
            if (!docSnapshot.exists()) {
                // If no document exists with the given ID, add a new document
                await setDoc(userDocRef, userDocData);
                console.log("User document added successfully");
            } else {
                // If a document exists, update the existing document
                await setDoc(userDocRef, userDocData, { merge: true });
                console.log("User document updated successfully");
            }
        } catch (error) {
            console.error("Error in setting user data: ", error);
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: error.message,
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        }
    }

    // const checkIfUserExists = (email,password,response) => {
    //     const user = { email: email }; // Create an object with the email property
    
    //     fetchSignInMethodsForEmail(user.email)
    //         .then((methods) => {
    //             console.log('methods: ', methods);
    //             user._canInitEmulator = true; // Assign properties to the object, not the string
    //             // Handle methods
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // }
    

    // const handleCheckUser = async (email,password,response) => {
    //     try {
    //       const userExists = await checkIfUserExists(email);
    //       console.log('userExists: ', userExists);
    //     //   setIsRegistered(userExists);
    //     } catch (err) {
    //     //   setError('Failed to check user registration status');
    //     }
    // };


    return (
        <div className="wrapper-page">
            <div className="card">
                <div className="card-body">
                    <div className="text-center" style={{ height: 70 }}>
                        <Link to="/" className="logo logo-admin"><img src={process.env.PUBLIC_URL + "/assets/images/logo-lead.png"} height="75" width="70" alt="logo" /></Link>
                    </div>
                    <div className="p-1">
                        <h4 className="text-muted font-18 m-b-5 text-center">Welcome Back !</h4>
                        <p className="text-muted text-center">Sign in to continue to TLM.</p>

                        <form className="form-horizontal m-t-30" onSubmit={handleSubmit(onSubmit)} >

                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" {...register('email')} className="form-control" id="email" placeholder="Enter Email" />
                                <div className="invalid-feedback">{errors.email?.message}</div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="userpassword">Password</label>
                                <input type="password" {...register('password')} className="form-control" id="userpassword" placeholder="Enter Password" />
                                <div className="invalid-feedback">{errors.password?.message}</div>
                            </div>

                            <div className="form-group row m-t-30">

                                <div className="col-12 text-center">
                                    <button className="btn btn-primary w-md waves-effect waves-light" type="submit" style={{ backgroundColor: "#043a69" }}>Login</button>
                                    {/* <Link className="btn btn-primary w-md waves-effect waves-light" type="submit">Login</Link> */}
                                </div>
                            </div>

                            {/* <div className="form-group m-t-10 mb-0 row">
                                <div className="col-12 m-t-20">
                                    <Link to="/forgotPassword" className="text-muted btn btn-link"><i className="mdi mdi-lock"></i> Forgot your password?</Link>
                                </div>
                            </div> */}
                        </form>
                    </div>
                </div>
            </div>
            {/* <div className="m-t-40 text-center">
                <p>© {new Date().getFullYear()} ISL Cargo. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
            </div> */}
        </div>
    );
}


export default Login;