import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { editProfile, getAdmin } from '../../api/apiHandler';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

function Profile() {

    const [adminDetails, setAdminDetails] = useState(null)    

    useEffect(() => {
        getAdmin({}).then((res) => {
            res = JSON.parse(res);
            if (res.code === 200) {
                setAdminDetails(res.data);
            }
        });
    }, []);

    // useEffect(() => {

    //     getAdmin({}).then((res) => {

    //         res = JSON.parse(res)
    //         if (res.code === 200) {

    //             console.log("=========", res.data)
    //             setAdminDetails(res.data)

    //             console.log("admindetails", adminDetails)
    //         }
    //     });
    //     console.log("--------------", adminDetails)
    // }, [])

    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required("Please enter first name").matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field').max(128, "Name must be at most 128 character"),
        lastname: Yup.string().required("Please enter last name").matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field').max(128, "Name must be at most 128 character"),
        // email: Yup.string().email().required("Please enter email"),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const navigate = useNavigate();

    function redirect() {
        navigate("/")
    }

    const onSubmit = (data) => {

        editProfile({ 
            "firstname": data.firstname.trimStart().trimEnd(), 
            "lastname": data.lastname.trimStart().trimEnd(),
         }).then((response) => {
            response = JSON.parse(response)
            if (response.code === 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    navigate("/")
                }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    if (adminDetails == null) return <> </>

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <ToastContainer />
                    <div className='cal col-xs-4 col-md-4 col-sm-4'>
                        <h5>Edit Profile</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className="form-group">
                                    <label>First Name</label>
                                    <div>
                                        <input type="text" {...register('firstname')} defaultValue={adminDetails.firstname} className="form-control" placeholder="Please enter first name" />
                                        <div className="invalid-feedback">{errors.firstname?.message}</div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label>Last Name</label>
                                    <div>
                                        <input type="text" {...register('lastname')} defaultValue={adminDetails.lastname} className="form-control" placeholder="Please enter last name" />
                                        <div className="invalid-feedback">{errors.lastname?.message}</div>
                                    </div>
                                </div>

                                {/* <div className="form-group">
                                    <label>Profile Picture</label>
                                    <div>
                                        <input type="text" {...register('profile_picture')} defaultValue={adminDetails.profile_picture} className="" />
                                    </div>
                                </div> */}

                                {/* <div className="form-group">
                                    <label htmlFor="profile_picture">Profile Image</label>
                                    <div className="clearfix"></div>
                                    <span onClick={() => uploadImage()}>
                                        <img src={imagePreview == null ? process.env.PUBLIC_URL + "/assets/images/userLogo.png" : imagePreview} className="rounded-circle" height={"80px"} width={"80px"} alt="userImage"  />
                                    </span>

                                    <input type="file" accept="image/*" ref={fileRef} className="d-none" id="profile_picture" name="profile_picture" onChange={(e) => uploadImageEvent(e)} />
                                </div> */}

                                {/* <div className="form-group">
                                    <label> Email</label>
                                    <div>
                                        <input type="text" {...register('email')} className="form-control" defaultValue={adminDetails.email} placeholder="Please enter email address" />
                                        <div className="invalid-feedback">{errors.email?.message}</div>
                                    </div>
                                </div> */}

                                <div className="form-group">

                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2">
                                        Edit
                                    </button>
                                    <button className="btn btn-danger" onClick={redirect} > Cancel </button>

                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile;