// import { Link, useNavigate, useParams } from 'react-router-dom';
// import { bodyDecryption } from '../common/RedirectPathMange';
// import { usersFeedbackList } from '../../api/apiHandler';
// import { useState, useEffect } from 'react';
// import ReactPaginate from 'react-paginate';

// const UsersFeedbackList = () => {

//     let { id } = useParams();
//     var decId = decodeURIComponent(bodyDecryption(id))

//     const [feedbackList, setFeedbackList] = useState([]);

//     useEffect(() => {
//         usersFeedbackList({ "user_id": decId })
//             .then((response) => {
//                 var res = JSON.parse(response)
//                 console.log(res.data)
//                 if (res.data && res.data.length > 0) {
//                     setFeedbackList(res.data);
//                     console.log(feedbackList)
//                 }
//                 else (setFeedbackList([]))
//             })
//             .catch((error) => {
//                 console.error('Error fetching feedback data:', error);
//             });
//     }, [decId]);
//     const longTextCellStyle = {
//         maxWidth: '200px',      // Set a max width for the cell
//         whiteSpace: 'nowrap',   // Prevent text from wrapping
//         overflow: 'hidden',    // Hide overflowing text
//         textOverflow: 'ellipsis'  // Add an ellipsis (...) when text is too long
//     };
//     return (
//         <>
//             <div className='content-page mt-5'>
//                 <div className='content'>
//                     <div className='container-fluid'>
//                         <div className='cal'>
//                             {feedbackList.length > 0 ? (
//                                 <table id="mangeUser" className="table table-hover dataTable js-exportable">
//                                     <thead>
//                                         <tr>
//                                             <th>ID</th>
//                                             <th>Name</th>
//                                             <th>Comment</th>
//                                             <th>Email 1</th>
//                                             <th>Email 2</th>
//                                             <th>Phone No.1</th>
//                                             <th>Phone No.2</th>
//                                             <th>Ease of use</th>
//                                             <th>Like aiva to do</th>
//                                             <th>Recommend aiva</th>
//                                             <th>Overall rating</th>
//                                             <th>City, Country</th>
//                                             <th>Date Time</th>
//                                         </tr>
//                                     </thead>

//                                     <tbody>
//                                         {
//                                             feedbackList && feedbackList.map((item, index) => {
//                                                 const date = new Date(item.date);
//                                                 const day = date.getUTCDate();
//                                                 const month = date.getUTCMonth() + 1; // Months are 0-based, so add 1
//                                                 const year = date.getUTCFullYear();
//                                                 const hours = date.getUTCHours();
//                                                 const minutes = date.getUTCMinutes();
//                                                 const formattedUTC = `${day}-${month}-${year} ${hours}:${minutes} `;
//                                                 return (
//                                                     <tr id={item.id} key={index}>
//                                                         <td >{item.id}</td>
//                                                         <td >{item.first_name} {item.last_name}</td>
//                                                         <td style={longTextCellStyle}>{item.comments}</td>
//                                                         <td>{item.email1}</td>
//                                                         <td>{item.email2}</td>
//                                                         <td>{item.phone_no1}</td>
//                                                         <td>{item.phone_no2}</td>
//                                                         <td>{item.ease_of_use}</td>
//                                                         <td>{item.like_aiva_to_do}</td>
//                                                         <td>{item.recommend_aiva}</td>
//                                                         <td>{item.overall_rating}</td>
//                                                         <td>{item.city}, {item.country}</td>
//                                                         <td>{formattedUTC}</td>
//                                                     </tr>
//                                                 )
//                                             })
//                                         }
//                                     </tbody>
//                                 </table>
//                             ) : (
//                                 feedbackList.length === 0 && <p>No feedback data available.</p>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//             </div >
//         </>
//     )
// }
// export default UsersFeedbackList

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { bodyDecryption } from '../common/RedirectPathMange';
import { usersFeedbackList } from '../../api/apiHandler';

const UsersFeedbackList = () => {
    let { id } = useParams();
    var decId = decodeURIComponent(bodyDecryption(id));

    const [feedbackList, setFeedbackList] = useState([]);
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);

    useEffect(() => {
        usersFeedbackList({ "user_id": decId })
            .then((response) => {
                var res = JSON.parse(response);
                if (res.data && res.data.length > 0) {
                    setFeedbackList(res.data);
                } else {
                    setFeedbackList([]);
                }
            })
            .catch((error) => {
                console.error('Error fetching feedback data:', error);
            });
    }, [decId]);

    const toggleRow = (index) => {
        setExpandedRowIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>
                            {feedbackList.length > 0 ? (
                                <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Name</th>
                                            <th>Comment</th>
                                            <th>Email 1</th>
                                            <th>Email 2</th>
                                            <th>Phone No.1</th>
                                            <th>Phone No.2</th>
                                            <th>Ease of use</th>
                                            <th>Like aiva to do</th>
                                            <th>Recommend aiva</th>
                                            <th>Overall rating</th>
                                            <th>City, Country</th>
                                            <th>Date Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {feedbackList && feedbackList.map((item, index) => {
                                            const date = new Date(item.date);
                                            const day = date.getUTCDate();
                                            const month = date.getUTCMonth() + 1;
                                            const year = date.getUTCFullYear();
                                            const hours = date.getUTCHours();
                                            const minutes = date.getUTCMinutes();
                                            const formattedUTC = `${day}-${month}-${year} ${hours}:${minutes} `;
                                            return (
                                                <>
                                                    <tr
                                                        id={item.id}
                                                        key={index}
                                                        onClick={() => toggleRow(index)}
                                                        className={expandedRowIndex === index ? 'expanded-row' : ''}
                                                    >
                                                        <td >{item.id}</td>
                                                        <td >{item.first_name} {item.middle_name} {item.last_name}</td>
                                                        <td className="longtext" >{item.comments}</td>
                                                        <td>{item.email1}</td>
                                                        <td>{item.email2}</td>
                                                        <td>{item.phone_no1}</td>
                                                        <td>{item.phone_no2}</td>
                                                        <td>{item.ease_of_use}</td>
                                                        <td>{item.like_aiva_to_do}</td>
                                                        <td>{item.recommend_aiva}</td>
                                                        <td>{item.overall_rating}</td>
                                                        <td>{item.city}, {item.country}</td>
                                                        <td>{formattedUTC}</td>
                                                    </tr>
                                                    {expandedRowIndex === index && (
                                                        <tr key={`expanded-${index}`} className="expanded-content">
                                                            <td colSpan="13">
                                                                <div className="expanded-details">
                                                                    <div className="left-side">
                                                                        <h3>{item.first_name} {item.middle_name} {item.last_name}</h3>
                                                                        <p><strong>Email 1 :</strong> {item.email1}</p>
                                                                        <p><strong>Email 2 :</strong> {item.email2}</p>
                                                                        <p><strong>Phone No.1 :</strong> {item.phone_no1}</p>
                                                                        <p><strong>Phone No.2 :</strong> {item.phone_no2}</p>
                                                                        <p><strong>City, Country :</strong> {item.city}, {item.country}</p>
                                                                    </div>
                                                                    <div className="right-side">
                                                                        <p><strong>Ease of Use :</strong> {item.ease_of_use}</p>
                                                                        <p><strong>Recommend aiva :</strong> {item.recommend_aiva}</p>

                                                                        <p><strong>Like aiva to do :</strong> {item.like_aiva_to_do}</p>
                                                                        <p><strong>Overall Rating :</strong> {item.overall_rating}</p>

                                                                    </div>
                                                                    <div className="comment">
                                                                        <p><strong>Comment :</strong> {item.comments}</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}

                                                </>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            ) : (
                                feedbackList.length === 0 && <p>No feedback data available.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UsersFeedbackList;
